
Object.defineProperty(exports, "__esModule", { value: true });
const colorDark = '#222';
const colorDark2 = '#666';
const colorGray = '#e3e3e3';
const colorWhite = '#fff';
const styles = {
    dark: {
        color: colorDark,
    },
    white: {
        color: colorWhite,
    },
    'bg-dark': {
        backgroundColor: colorDark2,
    },
    'bg-gray': {
        backgroundColor: colorGray,
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',

    },
    'w-auto': {
        flex: 1,
        paddingRight: '8px',
    },
    'ml-30': {
        flex: 1,
    },
    'w-100': {
        width: '100%',
    },
    'w-85': {
        width: '85%',
    },
    'w-50': {
        width: '50%',
    },
    'w-55': {
        width: '55%',
    },
    'w-45': {
        width: '45%',
    },
    'w-60': {
        width: '60%',
    },
    'w-40': {
        width: '40%',
    },
    'w-48': {
        width: '48%',
    },
    'w-17': {
        width: '17%',
    },
    'w-7': {
        width: '7%',
    },
    'w-18': {
        width: '18%',
    },
    'w-10': {
        width: '10%',
    },
    'w-35': {
        width: '35%',
    },

    'mt-40': {
        marginTop: '40px',
    },
    'mt-30': {
        marginTop: '30px',
    },
    'mt-20': {
        marginTop: '20px',
    },
    'mt-10': {
        marginTop: '10px',
    },
    'mr-10': {
        marginRight: '10px',
    },
    'ml-10': {
        marginLeft: '10px',
    },
    'mb-5': {
        marginBottom: '5px',
    },
    'mb-0': {
        marginBottom: '0px',
    },
    'p-4-8': {
        padding: '4px 8px',
    },
    'p-5': {
        padding: '5px',

    },
    'pr-5': {
        paddingRight: '8px',
    },
    'pb-10': {
        paddingBottom: '10px',
    },
    right: {
        textAlign: 'right',
    },
    bold: {
        fontWeight: 'bold',
    },
    'fs-20': {
        fontSize: '15px',
    },
    'fs-12': {
        fontSize: '12px',
    },

    'fs-45': {
        fontSize: '25px',
    },
    page: {
        fontFamily: 'Nunito',
        fontSize: '12px',
        color: '#555',
        padding: '40px 35px',
    },
    span: {
        padding: '4px 12px 4px 0',
    },
    logo: {
        display: 'block',
    },

    'invoiceTxt': {
        fontSize: '11px'
    },
    'pdfFontSize': {
        fontSize: '12px'
    },
    'pdfPadding': { padding: '40px 35px' },
    'text-start': {
        textAlign: 'left',
        paddingLeft: '5px'
    },
    'float-right': {
        float: 'right'
    },
    'invoiceLRI': {
        fontSize: '12px',
        fontWeight: 'bold'
    },
    'invoiceBox': {
        border: `5px solid ${colorDark}`
    },
    'invoiceBoxTitle' : {
        backgroundColor: `${colorDark}`,
        color: 'white',
        textAlign: 'center',
        padding: '0px 5px',
        height: '25px',
      },
      
    'row': {
        position: 'relative',
        wordWrap: 'break-word',
      
    },
    'col': {

        columns: '100% 1',
        wordWrap: 'break-word',
        marginRight: 'auto',
        marginLeft: 'auto',
         width: '50%'
    },
    'columns': {
        columnCount: '2',
        columnGap: '25px',
        columnRule: '2px solid #3366FF'
    },
    'text-end': {
        textAlign: 'right'
    },
    'text-center': {
        textAlign: 'center',
         
    },
    'float-end':{
        float: 'right'
    }
    ,'borderTest': {
        border: `5px solid ${colorGray}`,
        position: 'relative',
    },
    'floatleft': {
        alignContent: 'left'
    }

};
const _default = styles;
export { _default as default };

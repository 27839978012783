import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/authContext";
import { FetchContext } from "../context/FetchContext";
import { Row, Col, Button, Spinner, Form } from "react-bootstrap";
import ProjectCard from "../components/ProjectCard";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import Invoice from "../components/Invoice2";
import AlertMsg from "../components/PopUp";



const Projects = () => {
    // console.log(props)
    const { authState } = useContext(AuthContext);
    // const fetchContext = useContext(FetchContext);
    const [loadMsg, setLoadMsg] = useState('Loading Your Projects')
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(true);
    const [projObject, setPorjObject] = useState({});
    const [conObject, setConObject] = useState({});
    const [invoiceShow, setInvoiceShow] = useState(false);
    const [saved, setSaved] = useState(false)
    // const [filterObject, setFilterObject] = useState({});
    const nav = useNavigate();
    const { state } = useLocation();
 console.log(state)
    const [width, setWidth] = useState()
    useEffect(() => {
        if (!state) {
            nav('/')
        }
        setIsLoading(true);
        setProject(state)
        setIsLoading(false)
    }, [refresh, authState]);

    function setProject(state) {
        setPorjObject(state.proj3)
        setRefresh(false)

    }


 
    return (
        <>
            {invoiceShow ? (
                <>
                    <Row className="justify-content-center app">
                        <h5 className="text-center">Invoice Creation</h5>
                        <div>

                            <Invoice data={state.proj3} btnSelect={true} invShow={() => { setInvoiceShow(false); setLoadMsg('Sending your Invoice') }} setSaved={() => setSaved(true)} setLoad={() => setIsLoading(true)} setInvShow={() => setInvoiceShow(false)} />

                        </div>

                        <Button style={{ width: "150px" }} className="btn text-white my-5 btn-danger mobbtn" onClick={e => setInvoiceShow(false)}>Cancel</Button>

                    </Row>
                </>
            ) :
                (<>
                    {saved ? (<AlertMsg message='Your invoice was saved. You can modify it on the Invoices page' showButton="true" title="Success!" vari="success" link="Invoices" setLoad={r => setIsLoading(false)} setCancel={() => setSaved(false)} />) : (<Row className="justify-content-center">
                        {isLoading ? (
                            <>
                                <p className="pl-3 text-center">{loadMsg}</p>
                                <Spinner animation="border" variant="danger" />
                            </>
                        ) : (<>

                            <h4 className="text-center">{projObject.ProjectName}</h4>
                            <div className="project-form-container  ">
                                <Row className="justify-content-center projForm ">
                                
                                        {/* {projObject.ProjectNumber} */}
                                        <Row className="mb-1 justify-content-center ">
                                            <Col md="auto" className="text-center">
                                                <label>Company Name</label>
                                                <p className="formField px-3" >{projObject.Company_Name}</p>
                                                {/* <Form.Group controlId="formGridEmail">
                                                    <Form.Label>Company Name</Form.Label>
                                                    <Form.Control className="formField text-center" type="text" readOnly value={projObject.ProjectName} disabled />
                                                </Form.Group> */}
                                            </Col>
                                        </Row>
                                        <Row className="mb-1 justify-content-center ">
                                            <Col md="auto" className="text-center">
                                                {/* <Form.Group controlId="formGridtext">
                                                    <Form.Label>Title</Form.Label>
                                                    <Form.Control className="formField text-center" type="text" readOnly value={projObject.Title} disabled />
                                                </Form.Group> */}
                                                <label>Title</label>
                                                <p className="formField py-1 px-2" >{projObject.Title}</p>
                                            </Col>
                                            <Col md="auto" className="text-center">
                                                {/* <Form.Group controlId="formGridtext">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control className="formField text-center" type="text" readOnly value={projObject.Name} disabled />
                                                </Form.Group> */}
                                                <label>Name</label>
                                                <p className="formField py-1 px-2" >{projObject.Name}</p>
                                            </Col>
                                            <Col md="auto" className="text-center">
                                                {/* <Form.Group sm={true} controlId="formGridtext">
                                                    <Form.Label>Statement Of Work</Form.Label>
                                                    <Form.Control className="formField text-center" type="text" readOnly value={projObject.Date} disabled />
                                                </Form.Group> */}
                                                <label>Statement Of Work</label>
                                                <p className="formField py-1 px-2" >{projObject.Date}</p>
                                            </Col>

                                        </Row>
                                            <Row className="mb-1 justify-content-center ">
                                        <Col md="auto" className="text-center">
                                            {/* <Form.Group className="mb-1" controlId="formGridAddress1">
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control className="formField text-center" type="text" readOnly value={projObject.Address} disabled />
                                            </Form.Group> */}
                                            <label>Address</label>
                                            <p className="formField py-1 px-2" >{projObject.Address}</p>
                                        </Col>
                                        </Row>
                                            <Row className="mb-1 justify-content-center ">

                                            <Col xs="auto" className="text-center">
                                                {/* <Form.Group    >
                                                    <Form.Label>City</Form.Label>
                                                    <Form.Control className="formField text-center" type="text" readOnly value={projObject.City} disabled />
                                                </Form.Group> */}
                                                <label>City</label>
                                                <p className="formField py-1 px-2" >{projObject.City}</p>
                                            </Col>
                                            <Col xs="auto" className="text-center">
                                                {/* <Form.Group    >
                                                    <Form.Label>State</Form.Label>
                                                    <Form.Control className="formField text-center" type="text" readOnly value={projObject.State} disabled />
                                                </Form.Group> */}
                                                     <label>State</label>
                                                <p className="formField py-1 px-2" >{projObject.State}</p>
                                            </Col>
                                            <Col xs="auto" className="text-center">
                                                {/* <Form.Group   >
                                                    <Form.Label>Zip</Form.Label>
                                                    <Form.Control className="formField text-center" type="text" readOnly value={projObject.Zip} disabled />
                                                </Form.Group> */}
                                                     <label>Zip</label>
                                                <p className="formField py-1 px-2" >{projObject.Zip}</p>
                                            </Col>
                                        </Row>
                                        <Row className="mb-1  justify-content-center ">
                                            <Col md="auto" className="text-center">
                                            <label>Phone</label>
                                            <p className="formField py-1 px-2" >{projObject.Phone}</p>   
                                            </Col>
                                            <Col md="auto" className="text-center"> 
                                             <label>Email</label>
                                            <p className="formField py-1 px-2" >{projObject.Email}</p>
                                            </Col>
                                            {/* <Form.Group sm={true}>
                                                <Form.Label>Phone</Form.Label>
                                                <Form.Control className="formField text-center" type="text" readOnly value={projObject.Phone} disabled />
                                            </Form.Group> */}
                                            {/* <Form.Group sm={true}>
                                                <Form.Label>Email</Form.Label>
                                                <Form.Control className="formField text-center" type="text" readOnly value={projObject.Email} disabled />
                                            </Form.Group> */}
                                        </Row>
                               
                                </Row>
                            </div>

                        </>
                        )}

                        <Button style={{ width: "180px" }} className="btn text-white text-center my-5 mobbtn" onClick={(e) => setInvoiceShow(true)}>Create Invoice</Button>
                    </Row >)}
                </>
                )
            }
        </>

    )
}

export default Projects
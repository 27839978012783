import React, { lazy, Suspense, useContext, useState, useEffect, useCallback } from "react";
import { AuthContext } from "../context/authContext";
import { FetchContext } from "../context/FetchContext";
import { Row, Spinner } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";

const ProjectCard = lazy(() => import("../components/ProjectCard"));

const Projects = () => {
    const auth = useContext(AuthContext);
    const { authState } = auth;
    const fetchContext = useContext(FetchContext);
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(true);
    const [projObject, setProjObject] = useState([]);
    const [filterObject, setFilterObject] = useState([]);
    const [noProj, setNoProj] = useState(false);
    const history = useNavigate();

    useEffect(() => {
        setIsLoading(true);
        getProjects();
    }, [refresh, authState]);

    const getProjects = useCallback(async () => {
        try {
            const response = await fetchContext.authAxios.get("/getprojects");
            if (response.data.message === 'Session Expired') {
                auth.logout();
                return;
            }
            if (response.data === 'no records') {
                setNoProj(true);
                setFilterObject([]);
            } else {
                //  console.log('here')
                setProjObject(response.data);
                setFilterObject(response.data);
                setNoProj(false);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }, [fetchContext, auth]);

    const projectSearch = useCallback((e) => {
        const query = e.target.value.toLowerCase();
        const filteredProjects = projObject.filter(proj =>
            proj.fieldData.ProjectName.toLowerCase().includes(query) ||
            proj.fieldData.Company_Name.toLowerCase().includes(query)
        );
        setFilterObject(filteredProjects);
    }, [projObject]);

    return (
        <>
            <h1 className="text-center">Projects</h1>
            <Row className="justify-content-center">
                {isLoading ? (
                    <>
                        <p className="pl-3 text-center">Gathering your projects.</p>
                        <Spinner animation="border" variant="danger" />
                    </>
                ) : noProj ? (
                    <Row className="text-center">
                        <h5 className="text-center">Uh oh.</h5>
                        <p className="text-center">We didn't find any projects you are assigned to.</p>
                    </Row>
                ) : (
                    <>
                        <div className="text-center">
                            <p className="mb-0 pb-0">Project Search</p>
                            <br />
                            <input
                                onInput={projectSearch}
                                id="projectsearch"
                                className="mb-2 text-center inputsearch"
                                placeholder="Project Name or Company"
                                maxLength="75"
                                type="text"
                            />
                        </div>
                        {filterObject.length ? (
                            filterObject.map((project) => (
                                <Suspense fallback={<Spinner animation="border" variant="danger" />} key={project.fieldData.ProjectID}>
                                    <ProjectCard key={project.fieldData.ProjectID} props={project} />
                                </Suspense>
                            ))
                        ) : (
                            <Row className="text-center">
                                <h5 className="text-center">Uh oh.</h5>
                                <p className="text-center">We didn't find any projects you are assigned to.</p>
                            </Row>
                        )}
                    </>
                )}
            </Row>
        </>
    );
};

export default Projects;

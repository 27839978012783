import { React, useEffect, useState } from "react";
import { Modal, Form, Button, Image, Row, Col } from "react-bootstrap";
import { Check } from 'react-bootstrap-icons';
import Compressor from 'compressorjs';
const ImageModal = ({ info, onUpload }) => {

    const [show, setShow] = useState(false);
    const [pictures, setPictures] = useState([])
    const [file, setFile] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [baseSixFour, setBaseSixFour] = useState('')
    const [subbed, setSubbed] = useState(false)
    useEffect(() => {
     
        // setPictures([...pictures, { blob: URL.createObjectURL(event.target.files[0]), filename: event.target.files[0].name }])
        if (info.fileName?.length > 0) {
            var qs = info.fileName.split(".").pop();
    
            if (!subbed) {
                if (qs === 'pdf') {
                    // console.log("is pdf")
                    setPictures([{ blob: `data:application/${qs};base64,` + info.base64Container, fileName: info.fileName }])
                    setFile([{ blob: `data:application/${qs};base64,` + info.base64Container, fileName: info.fileName }])

                } else {
                    setFile([{ blob: `data:image/${qs};base64,` + info.base64Container, fileName: info.fileName }])
                    setPictures([{ blob: `data:image/${qs};base64,` + info.base64Container, fileName: info.fileName }])
                }
            }

            
        }
    }, [info]);



    function compress(uf) {
        new Compressor(uf, {
            quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
            success: (compressedResult) => {
                // compressedResult has the compressed file.
                // Use the compressed file to upload the images to your server.        

                let reader = new FileReader();
                reader.readAsDataURL(compressedResult);
                reader.onload = function () {

                    let bsix = reader.result.replace('data:image;base64,', '')
                    let bsix2 = bsix.replace('data:image/jpeg;base64,', '')
                    let bsix3 = bsix2.replace('data:image/gif;base64,', '')

// console.log(uf)
                    setBaseSixFour(bsix)
                    setFile({ fileName: uf.name, b64: bsix3 },);
                    setPictures([{ blob: URL.createObjectURL(uf), fileName: uf.name, b64: bsix3}])
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };




            },
        })

        return true
    }

    async function handleInputChange(event) {
        const { name, value } = event.target;
        // console.log(event.target.files[0])
        if (event.target.name === 'files') {
            if (event.target.files.length !== 0) {

                //   let file1 = new Blob([event.target.files[0]], {
                //         type: "image"
                //     });
                let fileName = event.target.files[0].name;
                let ext = fileName.substr(fileName.lastIndexOf(".") + 1);
                // console.log(ext)
                // console.log(fileName)
                if (ext !== 'pdf') {
                    compress(event.target.files[0])
                } else {
                    let reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = function () {
                        let bsix = reader.result.replace('data:application/pdf;base64,', '')
                        // console.log(reader.result)
                        setBaseSixFour(bsix)
                        setFile({ fileName: fileName, b64: bsix },);
                        setPictures([{ blob: URL.createObjectURL(event.target.files[0]), fileName: event.target.files[0].name, b64: bsix}])
                    };
                    reader.onerror = function (error) {
                        console.log('Error: ', error);
                    };
                }


                // console.log(URL.createObjectURL(event.target.files[0]))
            }
        }
    }

    function deletefile(name) {
        // console.log(name)


        let pics = pictures.filter(picture => picture.fileName !== name)
        
         let fils =  Object.values(file).filter(fil => fil.name !== name)
         setFile(fils)
        setPictures(pics, console.log(pictures))

    }
    function handleSubmit(event) {
        // console.log(file)
        // console.log(pictures)
        // console.log(pictures.length)
        // if(pictures.length === 0){
        //     setPictures([{ blob:'', fileName: '', b64: ''}])
        // }
        setSubbed(true)
        onUpload(pictures)


    }

    // console.log(info)
    return (
        <>
        <button className="link row__addImage" aria-label="Add Receipt" title="Add Receipt" onClick={() => setShow(true)}>
            {pictures.length >= 1 ? (
                <span className="iconAddItem icon-check bg-primary">
                    <Check color='white' size={20} />
                </span>
            ) : (
                <span className="iconAddItem icon-add bg-primary"></span>
            )}
        </button>
    
        <Modal show={show} onHide={handleClose} animation={false} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Receipt Upload</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                This Receipt is for <strong>{info.ProductType}</strong>
                {pictures.length >= 1 ? (
                    ''
                ) : (
                    <Form className="mt-3" onSubmit={() => onUpload(baseSixFour)}>
                        <Form.Group controlId="formFileMultiple" className="mb-3">
                            <Form.Control
                                name="files"
                                type="file"
                                accept="image/gif, image/jpeg, application/pdf"
                                onChange={(e) => handleInputChange(e)}
                            />
                        </Form.Group>
                    </Form>
                )}
                {pictures.length ? (
                    pictures.map((file, index) => (
                        <Row className="justify-content-center" key={index}>
                            <Col md={8}>
                                <button
                                    name={file.fileName}
                                    className="link picDelete float-end"
                                    aria-label="Remove File"
                                    title="Remove File"
                                    onClick={() => deletefile(file.fileName)}
                                >
                                    <span className="icon icon-remove bg-red"></span>
                                </button>
                                {file.fileName.substr(file.fileName.lastIndexOf(".") + 1) === 'pdf' ? (
                                    <iframe src={file.blob} title={file.fileName} width="100%" height="500px"></iframe>
                                ) : (
                                    <Image className="img-fluid" src={file.blob} alt="Card image cap"></Image>
                                )}
                            </Col>
                        </Row>
                    ))
                ) : (
                    ""
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" type="submit" onClick={() => { handleClose(); handleSubmit() }}>
                    Save
                </Button>
                <div className="mx-auto ">
                <p >You still need to save the draft for the receipt to be stored.</p>
                </div>
            </Modal.Footer>
        </Modal>
    </>
    
    );
}

export default ImageModal
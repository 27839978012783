import React, { useEffect, useState, useContext } from 'react'
import { PDFDownloadLink   } from '@react-pdf/renderer'
import { Spinner } from "react-bootstrap";
import Invoice from './Invoice'
import { AuthContext } from "../context/authContext";
import { FetchContext } from "../context/FetchContext";
 
const Download = ({ data,fileName }) => {
  const auth = useContext(AuthContext);
  const cons = auth.authState.userInfo;
  const [show, setShow] =  useState(false);
  // console.log("data down PDF")
  // console.log(data)
  useEffect(() => {
      setShow(false);
      const timeout = setTimeout(() => {
          setShow(true);
      }, 500);
      return () => clearTimeout(timeout);
  }, [data]);
 
  
  return (<div className={ (!show ? 'loading' : '')} title="Save PDF">
    {!show && <Spinner size='sm' animation="border" variant="light" />}
    {show && (
              <PDFDownloadLink
        
              document={<Invoice pdfMode={true} data={data} cons={cons} debug={true} />}
              fileName={fileName ? fileName+".pdf" : "invoice.pdf"}
              aria-label="Save PDF"
              // onClick={console.log("pdf click")}
              className="text-light"
            >Download PDF</PDFDownloadLink>
    )}
  </div>);
};
export default   Download;

 
//< PDFDownloadLink document={<InvoicePage pdfMode={true}   />} fileName='invoice.pdf' onClick={() => console.log("download")} className="text-light">Download PDF</ PDFDownloadLink>
import React, { useContext, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Row, Button, Form, Image, InputGroup, Spinner } from "react-bootstrap";
import Logo from "../pics/LRIRightNowLogo.png"
import { AuthContext } from "../context/authContext";
import { FetchContext } from "../context/FetchContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";

export default function Login() {
  const authContext = useContext(AuthContext);
  const fetchContext = useContext(FetchContext);
  const { authState } = useContext(AuthContext);
  const history = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [passText, setPassText] = useState();
  const [showPassText, setShowPassText] = useState(false);
  const [redirectOnLogin, setRedirectOnLogin] = useState(false);
  const [formObject, setFormObject] = useState({
    password1: "",
    password2: "",
  });
  const [isLoading, setIsLoading] = useState(false)
  function handleInputChange(event) {
    const { name, value } = event.target;

    setPassText(false);
    setFormObject({ ...formObject, [name]: value });
  }

  useEffect(() => {
    checkForm();
  }, [checkForm, formObject, redirectOnLogin]);


  const [formFull, setFormFull] = useState(true);



  // eslint-disable-next-line react-hooks/exhaustive-deps
  function checkForm() {

    if (formObject.password2.length > 3) {
      if (
        formObject.password1.length > 1 && formObject.password1 === formObject.password2
      ) {
        setFormFull(true);
        setPassText(false);
        setShowPassText(false);

      } else {
        setFormFull(false);
        setPassText(true);
        setShowPassText(true);

      }
    }
  }
  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true)
    try {
      const { data } = await fetchContext.publicAxios.post("updatepassword", formObject);
      if (data === "Error") {
        //  console.log("something went wrong")
      } else {
        //  console.log("should redirect");
        history("/projects")
      }
    } catch (error) {
      console.log(error);
    }
  }

  function passview(e) {

    //  console.log(e)
    //  console.log(e === 'password1')
    if (e === 'password1') {
      if (showPass) {
        console.log('changing pass1')
        setShowPass(false)
        document.getElementById(e).type = "password"
      } else {
        setShowPass(true)
        document.getElementById(e).type = "text"

      }
    } else {
      if (showPass2) {
        //    console.log('changing pass2')
        setShowPass2(false)
        document.getElementById(e).type = "password"
      } else {
        //   console.log('changing pass2')
        setShowPass2(true)
        document.getElementById(e).type = "text"

      }
    }
  }


  return (

    <>
      {redirectOnLogin && <Navigate to="/dashboard" />}
      <Row

      >
        {isLoading ? (<>  <div className="Auth-form-container">

          <form className="Auth-form" onSubmit={handleSubmit}>
            <div className="Auth-form-content text-center">
              <Image src={Logo} style={{ maxWidth: "95%" }} />
              <div className="my-5">
                <h1>
              <Spinner className="mx-auto" animation="border" variant="warning" size="xl"  />
              </h1>
              </div>
            </div>
          </form>
        </div></>) : (<>  <div className="Auth-form-container">

          <form className="Auth-form" onSubmit={handleSubmit}>
            <div className="Auth-form-content">
              <Image src={Logo} style={{ maxWidth: "95%" }} />
              <h3 className="Auth-form-title">Set Password</h3>

              <label>New Password</label>

              <InputGroup className="mb-3">
                <Form.Control
                  type="password"
                  name="password1"
                  id="password1"
                  placeholder="Enter Password"
                  aria-label="Enter Password"
                  onChange={(e) => handleInputChange(e)}
                  aria-describedby="basic-addon2"
                />

                <Button variant="outline-secondary" id="button-addon1" onClick={(e) => passview("password1")}>
                  {showPass ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                </Button>
              </InputGroup>
              <InputGroup className="mb-3">
                <Form.Control
                  type="password"
                  name="password2"
                  id="password2"
                  placeholder="Enter Password"
                  aria-label="Enter Password"
                  onChange={(e) => handleInputChange(e)}
                  aria-describedby="basic-addon2"
                />
                <Button variant="outline-secondary" id="button-addon2" onClick={(e) => passview("password2")}>
                  {showPass2 ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                </Button>
              </InputGroup>

              <div className="d-grid gap-2 mt-3">
                {showPassText ? <p className="text-danger fs-6">The passwords do not match</p> : ''}
                <button type="submit" className="btn btn-primary" disabled={formFull ? false : true}>
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div></>)}

      </Row>

    </>

  );
}

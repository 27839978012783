import { React, useEffect, useState, useContext } from "react";
import { AuthContext } from "../context/authContext";
import { FetchContext } from "../context/FetchContext";
import { Row, Container, Spinner, Tabs, Tab } from "react-bootstrap";
import Invoice from "../components/Invoice";
import Accordion from "../components/Accordion"
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { decompress } from 'compress-json'
const Invoices = () => {

    const auth = useContext(AuthContext);
    const cons = auth.authState.userInfo;
    const fetchContext = useContext(FetchContext);
    // const [cons, setCons] = useState()
    const [noRecords, setNoRecords] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(true);
    const [invoice, setPorjObject] = useState([]);

    const [workingInvoices, setWorkingInvoices] = useState([])
    const [filtworkingInvoices, setFiltWorkingInvoices] = useState([])
    const [lockedInvoices, setLockedInvoices] = useState([])
    const [filtlockedInvoices, setFiltLockedInvoices] = useState([])
    const [processedInvoices, setProcessedInvoices] = useState([])
    const [filtprocessedInvoices, setFiltProcessedInvoices] = useState([])
    const history = useNavigate();
    useEffect(() => {
        // console.log("dashboard")
        setIsLoading(true);
        getProjects();
        onCall();
    }, [refresh, auth.authState]);
    // console.log(invoice)
    async function getProjects() {
        try {
            await fetchContext.authAxios.get("/getinvoices").then((res) => {

                if (res.data.message === 'Session Expired') {
                    auth.logout()

                }
                if (res.data.message === 'No Records') {
                    setIsLoading(false)
                    setNoRecords(true)
                }
                let decom = decompress(res.data)
               // console.log(decom)
                let startInvoices = decom
                let invCom = [];
                // let lineItems = [];
                // let locations = [];
                const invoices = startInvoices.forEach(inv => {

                    let invoiceData = inv.fieldData
                    // console.log(inv)
                    let lineItems = [];
                    let locations = [];
                    let payments = []
                    inv.portalData["ConsultInv | PaymentsCons"].forEach((py, i) => {

                        // console.log(py)
                        let payData = {
                            datePaid: py["ConsultInv | PaymentsCons::DatePd"],
                            pay: py["ConsultInv | PaymentsCons::Pay"],
                            payType: py["ConsultInv | PaymentsCons::PayType"],
                        }
                        // console.log(liData)
                        payments.push(payData)
                        return payData
                    })
                    inv.portalData["ConsultInv | ConsultInvItems"].forEach((li, i) => {
                        // console.log(li["ConsultInv | ConsultInvItems::Price"].length)

                        let liData = {
                            quantity: li["ConsultInv | ConsultInvItems::Quantity"],
                            ProductType: li["ConsultInv | ConsultInvItems::ProductType"],
                            ProductDetail: li["ConsultInv | ConsultInvItems::ProductDetail"],
                            rate: li["ConsultInv | ConsultInvItems::Price"] !== '' ? parseFloat(li["ConsultInv | ConsultInvItems::Price"]).toFixed(2) : li["ConsultInv | ConsultInvItems::Price"],
                            unit: '',
                            amount: parseFloat(li["ConsultInv | ConsultInvItems::Quantity"] * li["ConsultInv | ConsultInvItems::Price"]).toFixed(2),
                            recordID: li.recordId,
                            base64Container: li["ConsultInv | ConsultInvItems::Base64Container"],
                            fileName: li["ConsultInv | ConsultInvItems::fileName"]
                        }
                        // console.log(liData)
                        lineItems.push(liData)
                        return liData
                    })
                    inv.portalData["ConsultInv | ConsultInvLoc"].forEach((lo, i) => {

                        // console.log(lo)
                        let loData = {
                            city: lo["ConsultInv | ConsultInvLoc::LocationCity"],
                            state: lo["ConsultInv | ConsultInvLoc::LocationState"],
                            recordID: lo.recordId
                        }
                        // console.log(loData)
                        locations.push(loData)

                    })
                    // console.log(locations)




                    let combined = Object.assign(Object.assign({}, invoiceData), { lineItems, locations, payments })


                    invCom.push(combined)
                })

                invCom.sort(function (a, b) {
                    return new Date(b.Invoice_Date) - new Date(a.Invoice_Date) || b.Invoice_Number - a.Invoice_Number;
                })
                setPorjObject(invCom)
                let workingInvoices = invCom.filter(invS => invS.Status === 'Working')
                let lockedInvoices = invCom.filter(invS => invS.Status === 'Finalized' || invS.Status === "Lock")
                let processedInvoices = invCom.filter(invS => invS.Status === 'Processed')
                setWorkingInvoices(workingInvoices)
                setLockedInvoices(lockedInvoices)
                setProcessedInvoices(processedInvoices)
                setFiltWorkingInvoices(workingInvoices)
                setFiltLockedInvoices(lockedInvoices)
                setFiltProcessedInvoices(processedInvoices)
                // setCons(authState.userInfo)
                setRefresh(false);
                setIsLoading(false)
            });

        } catch (error) {
            console.log(error);
        }
        // console.log(invoice)
    }
    function onCall() {
        // console.log(invoice)
    }
    function invoicesearch(e, invs) {
        // console.log("prject searc")
        // console.log(e.target.name)
        // console.log(invs.Invoice_Date)
        var x = e.target.value.toString().toLowerCase();
        // console.log(x)
        if (e.target.name === 'drafts') {
            let t = workingInvoices.filter(proj => proj.ProjectName.toLowerCase().includes(x) || proj.Company_Name.toLowerCase().includes(x) || proj.Invoice_Number.toString().includes(x) || proj.Invoice_Date.toString().includes(x));
            setFiltWorkingInvoices(t)

        }
        if (e.target.name === 'locked') {
            let t = lockedInvoices.filter(proj => proj.ProjectName.toLowerCase().includes(x) || proj.Company_Name.toLowerCase().includes(x) || proj.Invoice_Number.toString().includes(x) || proj.Invoice_Date.toString().includes(x));
            setFiltLockedInvoices(t)

        }
        if (e.target.name === 'processed') {
            let t = processedInvoices.filter(proj => proj.ProjectName.toLowerCase().includes(x) || proj.Company_Name.toLowerCase().includes(x) || proj.Invoice_Number.toString().includes(x) || proj.Invoice_Date.toString().includes(x));
            setFiltProcessedInvoices(t)

        }
    }

    return (
        <>
            <h1 className="text-center">Invoices</h1>
            <Row className="justify-content-center">
    {isLoading || invoice.length === undefined ? (
        <>
            <p className="pl-3 text-center">Gathering your invoices. This could take a moment.</p>
            <Spinner animation="border" variant="danger" />
        </>
    ) : (
        noRecords ? (
            <>
                <Container className="invoicesContainer">
                    <Row className="justify-content-center text-center">
                        <h5 className="text-center">There are no invoices.</h5>
                        <p>Go to the <a href="/projects">Projects Page</a> and select a project to create an invoice for.</p>
                        <FontAwesomeIcon icon={faCircleInfo} size="6x" />
                    </Row>
                </Container>
            </>
        ) : (
            <Container className="invoicesContainer">
                <Tabs defaultActiveKey="drafts" className="mb-3" justify>
                    {workingInvoices.length > 0 ? (
                        <Tab eventKey="drafts" title="Drafts">
                            <div className="text-center">
                                <p className="mb-0 pb-0">Invoice Search</p>
                                <br />
                                <input
                                    id="invoicesearch"
                                    name="drafts"
                                    onInput={e => invoicesearch(e, filtworkingInvoices)}
                                    className="mb-2 text-center inputsearch"
                                    placeholder="Project Name, Company, Date, Inv#"
                                    maxLength="95"
                                    type="text"
                                />
                            </div>
                            {filtworkingInvoices.map((invoice, i) => (
                                <Accordion
                                    key={i} // Ensure unique key
                                    id={i}
                                    name={[
                                        <p key={`${i}-name`} className="mb-0" style={{ width: 'max-content', display: 'inline-block' }}><strong>{invoice.Company_Name}</strong></p>,
                                        <div key={`${i}-details`}><p className="mb-0">{invoice.ProjectName}</p><br /><p className="mb-0">{invoice.Invoice_Date}</p><br /><p className="mb-0">{invoice.Invoice_Number}</p></div>
                                    ]}
                                    comp={<Invoice data={invoice} btnSelect={true} editable={true} cons={cons} setre={() => setRefresh(true)} />}
                                />
                            ))}
                        </Tab>
                    ) : (
                        <Tab eventKey="drafts" title="Drafts">
                            <div className="text-center">
                                <p className="mb-0 pb-0">There are currently no invoices saved as "Draft".</p>
                                <br />
                                <p>Go to the <a href="/projects">Projects Page</a> and select a project to create an invoice.</p>
                            </div>
                        </Tab>
                    )}
                    {lockedInvoices.length > 0 ? (
                        <Tab eventKey="submitted" title="Submitted">
                            <div className="text-center">
                                <p className="mb-0 pb-0">Invoice Search</p>
                                <br />
                                <input
                                    id="invoicesearch"
                                    name="locked"
                                    onInput={e => invoicesearch(e, filtlockedInvoices)}
                                    className="mb-2 text-center inputsearch"
                                    placeholder="Project Name, Company, Date, Inv#"
                                    maxLength="95"
                                    type="text"
                                />
                            </div>
                            {filtlockedInvoices.map((invoice, i) => (
                                <Accordion
                                    key={i} // Ensure unique key
                                    pays={invoice.payments}
                                    id={i}
                                    payDue={invoice["Amount Due Cons"]}
                                    name={[
                                        <p key={`${i}-name`} className="mb-0" style={{ width: 'max-content', display: 'inline-block' }}><strong>{invoice.Company_Name}</strong></p>,
                                        <div key={`${i}-details`}><p className="mb-0">{invoice.ProjectName}</p><br /><p className="mb-0">{invoice.Invoice_Date}</p><br /><p className="mb-0">{invoice.Invoice_Number}</p></div>
                                    ]}
                                    status={invoice["Payment Status"]}
                                    comp={<Invoice data={invoice} cons={cons} btnSelect={false} editable={false} />}
                                />
                            ))}
                        </Tab>
                    ) : (
                        <Tab eventKey="submitted" title="Submitted">
                            <div className="text-center">
                                <p className="mb-0 pb-0">There are currently no invoices submitted.</p>
                                <br />
                                <p>Go to the <a href="/projects">Projects Page</a> and select a project to create an invoice.</p>
                            </div>
                        </Tab>
                    )}
                    {processedInvoices.length > 0 ? (
                        <Tab eventKey="processed" title="Processed">
                            <div className="text-center">
                                <p className="mb-0 pb-0">Invoice Search</p>
                                <br />
                                <input
                                    id="invoicesearch"
                                    name="processed"
                                    onInput={e => invoicesearch(e, filtprocessedInvoices)}
                                    className="mb-2 text-center inputsearch"
                                    placeholder="Project Name, Company, Date, Inv#"
                                    maxLength="95"
                                    type="text"
                                />
                            </div>
                            {processedInvoices.map((invoice, i) => (
                                <Accordion
                                    key={i} // Ensure unique key
                                    pays={invoice.payments}
                                    id={i}
                                    payDue={invoice["Amount Due Cons"]}
                                    name={[
                                        <p key={`${i}-name`} className="mb-0" style={{ width: 'max-content', display: 'inline-block' }}><strong>{invoice.Company_Name}</strong></p>,
                                        <div key={`${i}-details`}><p className="mb-0">{invoice.ProjectName}</p><br /><p className="mb-0">{invoice.Invoice_Date}</p><br /><p className="mb-0">{invoice.Invoice_Number}</p></div>
                                    ]}
                                    status={invoice["Payment Status"]}
                                    comp={<Invoice cons={cons} data={invoice} btnSelect={false} editable={false} />}
                                />
                            ))}
                        </Tab>
                    ) : (
                        <Tab eventKey="processed" title="Processed">
                            <div className="text-center">
                                <p className="mb-0 pb-0">There are currently no processed invoices.</p>
                                <br />
                                <p>Go to the <a href="/projects">Projects Page</a> and select a project to create an invoice for.</p>
                            </div>
                        </Tab>
                    )}
                </Tabs>
            </Container>
        )
    )}
</Row>

        </>
    )
}

export default Invoices
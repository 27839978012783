import React, { useContext } from "react";
import Navbar from "./components/Nav";
import { Row, Spinner } from "react-bootstrap";
import { AuthContext } from "./context/authContext";
const LoadingLogo = () => {
  return (
    <Row className="justify-content-center">
      <Spinner animation="grow" variant="danger" />
    </Row>
  );
};

const AppShell = ({ children }) => {
  const { authState } = useContext(AuthContext);

  if (!authState.userInfo) {
    return (
      <div className="text-center">
        <LoadingLogo />
      </div>
    );
  }

  return (
    <>
   
   <Navbar />
      <div className="app" >{children}</div>
 
    </>
  );
};

export default AppShell;

import { React, useEffect, useState, useContext } from "react";
import { initialInvoice } from "../data/initialData";
import EditableInput from "./EditableInput";
import { Row, Button, Col, Container, Alert, Spinner} from "react-bootstrap";
import Document from "./Document";
import Page from "./Page";
import View from "./View";
import Text from "./Text";
import EditableTextarea from './EditableTextarea'
import EditableSelect from './EditableSelect'
import ImageModal from "./AddReceipt";
import { FetchContext } from "../context/FetchContext";
import { AuthContext } from "../context/authContext";
import Confirm from "./Confirm";
const Invoice = ({ user, data, pdfMode, onChange, btnSelect, setSaved, invShow, setLoad }) => {

  const auth = useContext(AuthContext);
  const cons = auth.authState.userInfo;
  const fetchContext = useContext(FetchContext);
  const [isLoading, setIsLoading] = useState(true)
  const [subTotal, setSubTotal] = useState();
  const [dupewarn, setDupeWarn] = useState(false)
 
  const [file, setFile] = useState([]);

  var today = new Date()
  let date = (today.getMonth() + 1) + '-' + today.getDate() + '-' + today.getFullYear();
  const descriptions = [
    { value: "", text: 'Select Product or Service' },
    { value: "Airfare", text: 'Airfare' },
    { value: "Airport Parking", text: 'Airport Parking' },
    { value: "Consultant Rate", text: 'Consultant Rate' },
    { value: "Fuel for Rental Car", text: 'Fuel for Rental Car' },
    { value: "Ground Transportation", text: 'Ground Transportation' },
    { value: "Hotel", text: 'Hotel' },
    { value: "Mileage", text: 'Mileage' },
    { value: "Per Diem", text: 'Per Diem' },
    { value: "Rental Car", text: 'Rental Car' },
    { value: "Travel Fee", text: 'Travel Fee' },
  ]
  const reportable = [
    { value: '', text: "Select" },
    { value: "Reportable", text: "Yes" },
    { value: "Non-Reportable", text: "No" }
  ]

  //console.log(user)
  //console.log(data)
  const [lineItems, setLineItems] = useState([]);
  const [invoice, setInvoice] = useState(data ? Object.assign({}, data) : Object.assign({}, initialInvoice));
  const [refresh, setRefresh] = useState(false)

  // console.log(invoice)
  function checkForm() {
 
    if (invoice.lineItems.length >= 1 && invoice.Reportable && invoice.ConsultantInvoice) {

      return false
    }
    else {

      return true
    }
  }

  function setInvoiceFunc() {
    setInvoice(() => data)
    setRefresh(true)
    setIsLoading(false)
  }

  useEffect(() => {
    setRefresh(false)
    setInvoiceFunc()
 

  }, [refresh]);
  useEffect(() => {
    if (onChange) {
      onChange(invoice);
    }
  }, [onChange, invoice]);


  useEffect(() => {
    let subTotal = 0;
    if (invoice.lineItems) {
      invoice.lineItems.forEach((lineItem) => {

        const quantityNumber = parseFloat(lineItem.quantity);
        const rateNumber = parseFloat(lineItem.rate);
        const amount = quantityNumber && rateNumber ? quantityNumber * rateNumber : 0;
        subTotal += amount;
      });
    }
    subTotal = parseFloat(subTotal).toFixed(2)

    setSubTotal(subTotal);
    setInvoice(Object.assign(Object.assign({}, invoice), { subTotal }))
    checkDupe(invoice)
  }, [invoice.lineItems]);
  // useEffect(() => {
  //   const match = invoice.taxLabel.match(/(\d+)%/);
  //   const taxRate = match ? parseFloat(match[1]) : 0;
  //   const saleTax = subTotal ? (subTotal * taxRate) / 100 : 0;
  //   setSaleTax(saleTax);
  // }, [subTotal, invoice.taxLabel]);


  const handleChange = (name, value) => {
    if (name !== 'lineItems') {
      const newInvoice = Object.assign({}, invoice);
      if (name === 'logoWidth' && typeof value === 'number') {
        newInvoice[name] = value;
      }
      else if (name !== 'logoWidth' && typeof value === 'string') {
        newInvoice[name] = value;
      }
      setInvoice(newInvoice);
      // console.log(invoice)
    }
  };

  const handleProductLineChange = (index, name, value) => {
    const lineItems = invoice.lineItems.map((lineItem, i) => {
      if (i === index) {
        const newLineItem = { ...lineItem }; // Using spread operator for a shallow copy
  
        if (name === 'ProductType') {
          newLineItem[name] = value;
          if (value === 'Consultant Rate') {
            newLineItem.rate = invoice.rateInfo.billRate.toFixed(2);
            newLineItem.unit = invoice.rateInfo.billType;
          } else {
            newLineItem.rate = '0.00';
            newLineItem.unit = '';
          }
        } else if (name === 'ProductDetail') {
         // console.log(value)
          newLineItem[name] = value;
        } else if (name === 'file') {
          if (value.length === 0) {
            newLineItem.base64Container = '';
            newLineItem.fileName = '';
          } else {
            newLineItem.base64Container = value[0].b64;
            newLineItem.fileName = value[0].fileName;
          }
        } else {
          let val = value.replace('$', '');
          if (val[val.length - 1] === '.' || (val[val.length - 1] === '0' && val.includes('.'))) {
            newLineItem[name] = val;
          } else {
            const n = parseFloat(val);
            newLineItem[name] = (n ? n : 0).toString();
          }
        }
        return newLineItem;
      }
      return { ...lineItem };
    });
  
    setInvoice({ ...invoice, lineItems });
  };
  

  // const handleProductLineChange = (index, name, value) => {


  //   const lineItems = invoice.lineItems.map((lineItem, i) => {
  //     if (i === index) {
  //       console.log(lineItem)
  //       const newLineItem = Object.assign({}, lineItem);;
  //       if (name === 'ProductType') {
  //         newLineItem[name] = value;
  //         if (value === 'Consultant Rate') {
  //           newLineItem.rate = invoice.rateInfo.billRate.toFixed(2);
  //           newLineItem.unit = invoice.rateInfo.billType;
  //         } else {
  //           newLineItem.rate = '0.00';
  //           newLineItem.unit = '';
  //         }
  //       } else if (name === "ProductDetail") {
  //         console.log(value)
  //         newLineItem[name] = value;
  //       } else if (name === 'file') {

  //         console.log("FILE SETTING")

  //         if (value.length === 0) {
  //           console.log("undefined asjfdhasjfhalfhakdh")
  //           newLineItem.base64Container = '';
  //           newLineItem.fileName = '';

  //         }
  //         else {
  //           newLineItem.base64Container = value[0].b64;
  //           newLineItem.fileName = value[0].fileName;
  //         }
  //       }
  //       else {
  //         let val = value.replace('$', '')
  //         if (val[val.length - 1] === '.' ||
  //           (val[val.length - 1] === '0' && val.includes('.'))) {
  //           newLineItem[name] = val;
  //         }
  //         else {
  //           const n = parseFloat(val);
  //           newLineItem[name] = (n ? n : 0).toString();

  //         }
  //       }
  //       return newLineItem;
  //     }
    
  //     return Object.assign({}, lineItem, file);
  //   });
  //   setInvoice(Object.assign(Object.assign({}, invoice), { lineItems }));

  //   // console.log(invoice)

  // };

  const handleFormat = (index, name, value) => {
    // console.log("handleFormat")
    // console.log(index)
    // console.log(name)
    // console.log(value)
    let val = value.replace('$', '')
    const lineItems = invoice.lineItems.map((lineItem, i) => {
      if (name === 'rate' && i === index && val.length > 0) {
        //console.log(lineItem)
        const newLineItem = Object.assign({}, lineItem);


        newLineItem[name] = parseFloat(val).toFixed(2)

        return newLineItem;
      }
      return Object.assign({}, lineItem);
    });
    setInvoice(Object.assign(Object.assign({}, invoice), { lineItems }));
    // console.log(invoice)



  };



  const handleRemoveLoc = (i, value) => {
    // console.log("location remove")
    // console.log(i)
    // console.log(value)
    const locations = invoice.locations.filter((loc, index) => index !== i);

    setInvoice(Object.assign(Object.assign({}, invoice), { locations }))
    // console.log(invoice)
  };
  const handleRemove = (i) => {
   // console.log(i)
    const lineItems = invoice.lineItems.filter((lineItem, index) => index !== i);
    //console.log(lineItem)
    setLineItems(lineItems)
    setInvoice(Object.assign(Object.assign({}, invoice), { lineItems }))
  };
  function handleAdd() {
  //  console.log("add line")
    const lineItems = [...invoice.lineItems, initialInvoice.lineItems[0]];
    setInvoice(Object.assign(Object.assign({}, invoice), { lineItems }));
  //  console.log(invoice.lineItems)
  };
  function handleLocationChange(index, name, value) {
    // console.log(index)
    // console.log(name)
    // console.log(value)


    // console.log(invoice.locations.map((loc, i) => loc))
    const locations = invoice.locations.map((loc, i) => {
      // console.log(i)
      // console.log(loc)
      const newLoc = Object.assign({}, loc);;
      if (i === index) {
        newLoc[name] = value;

        return newLoc;
      }
      return loc;
    });
    // console.log(invoice.locations)
    setInvoice(Object.assign(Object.assign({}, invoice), { locations }));
    // console.log(invoice)

  };

  function handleAddLoc() {
    // console.log("add location")
    const locations = [...invoice.locations, { "city": '', "state": '' }]
    setInvoice(Object.assign(Object.assign({}, invoice), { locations }))
  }
  const calculateAmount = (quantity, rate) => {
    const quantityNumber = parseFloat(quantity);
    const rateNumber = parseFloat(rate);
    const amount = quantityNumber && rateNumber ? quantityNumber * rateNumber : 0;
    return amount.toFixed(2);
  };
  // const handleSetRate = (i) => {
  //   console.log("setting rate")
  //   invoice.lineItems[i].rate = invoice.rate
  //   //console.log(user["Projects | ConsultantsAssign::BillRate"])
  //   console.log(lineItems[i])
  // }
  async function handleDraft(e) {
 
  //console.log("draft")
    // setLoad()
    // invShow()
    setIsLoading(true)
    try {
      await fetchContext.authAxios.post("/savedraft", invoice).then((res) => {
      //  console.log(res.data)
        if (res.data.message === 'Session Expired') {
          auth.logout()
        }
        if (res.data.message === 'Success') {
          setSaved()
          invShow()
          setIsLoading(false)
        }
      });
    } catch (error) {
      console.log(error);
    }
  }


  async function handleSubmit(e) {
   // console.log("submit")
    e.preventDefault();
    try {
      setIsLoading(true)
      await fetchContext.authAxios.post("/submitinvoice", invoice).then((res) => {
       // console.log(res.data)
        if (res.data.message === 'Session Expired') {
          auth.logout()
        }
        if (res.data.message === 'Success') {
          setSaved()
          setIsLoading(false)
          invShow()
        }
      });
    } catch (error) {
      console.log(error);
    }
  }


  function checkDupe(ie) {
//  console.log("checking dupe")
    var result = Object.values(ie.lineItems.reduce((c, v) => {
      // if (v.fileName?.length >= 1) {

      //   let k = v.ProductType + '-' + v.fileName;
      //   c[k] = c[k] || [];
      //   c[k].push(v);
      //   return c;
      // }
      // else {
       
        if(v.ProductType.length > 0  ){
          let k =  v.ProductType.trim();
          c[k] = c[k] || [];
          c[k].push(v);
          return c;
        }
 
        return c;
      // }
    }, {})).reduce((c, v) => v.length > 1 ? c.concat(v) : c, []);
    if (result.length >= 1){ setDupeWarn(true)}
    else{setDupeWarn(false)}

    // console.log(result);
  }


  return (<>
  {isLoading ? ( <>
                       <Row> <p className="pl-3 text-center">Saving invoice.</p></Row>
                       <Row> <Spinner className="mx-auto" animation="border" variant="danger" /></Row>
                    </>) : (   
    <Document className="app pdfPadding " pdfMode={pdfMode}>

<Page className="invoice-wrapper" pdfMode={pdfMode}>
  <View className="flex" pdfMode={pdfMode}>
    <View className="w-50" pdfMode={pdfMode}>

      <Text className='input bold' pdfMode={pdfMode} value={cons.NameFirst + " " + cons.NameLast} >{cons.NameFirst + " " + cons.NameLast}</Text>
      <Text className='input' pdfMode={pdfMode} value={cons.Address} >{cons.Company}</Text>
      <Text className='input' pdfMode={pdfMode} value={cons.Address} >{cons.Address}</Text>
      <Text className='input' pdfMode={pdfMode} value={cons.City + " " + cons.St + " " + cons.Zip} >{cons.City + " " + cons.St + " " + cons.Zip}</Text>
      <Text className='input' pdfMode={pdfMode} value={cons.Phone} >{cons.Phone}</Text>
    </View>

    <View className="w-50" pdfMode={pdfMode}>

      <View className="flex right" pdfMode={pdfMode}>
        <View className={pdfMode ? "w-65 float-right pr-5" : "w-65"} pdfMode={pdfMode}>
          <Text className="bold invoiceTxt" value="Invoice #:" pdfMode={pdfMode} >Consultant Invoice #:</Text>
        </View>
        <View className="w-50" pdfMode={pdfMode}>
          <EditableInput aria="Consultant Invoice #" title="Consultant Invoice #" placeholder="Invoice #" value={invoice.ConsultantInvoice} className="dark right text-center" onChange={(value) => handleChange('ConsultantInvoice', value)} pdfMode={pdfMode} />
        </View>
      </View>
    </View>
  </View>


  <View className="flex mt-40" pdfMode={pdfMode}>
    <View className="flex" pdfMode={pdfMode}>
      <View className="w-60" pdfMode={pdfMode}>
        <Text className='input bold' pdfMode={pdfMode} value="LRI Consulting Services, Inc." >LRI Consulting Services, Inc.</Text>
        <Text className='input' pdfMode={pdfMode} value="P.O. Box 1529" >P.O. Box 1529</Text>
        <Text className='input' pdfMode={pdfMode} value="Broken Arrow, OK 74013" >Broken Arrow, OK 74013</Text>
        <Text className='input' pdfMode={pdfMode} value="(918) 455-9995" >(918) 455-9995</Text>
      </View>


    </View>

    <View className=" w-100" pdfMode={pdfMode}>
      <View className="invoiceBox w-55 float-end" pdfMode={pdfMode}>

        <Text className='invoiceBoxTitle' value="Client Bill To:" pdfMode={pdfMode} >Client Bill To:</Text>

        <Text className='invoiceTxt' pdfMode={pdfMode} value={invoice.Name}>{invoice.Name}</Text>
        <Text className='invoiceTxt' pdfMode={pdfMode} value={invoice.Title}>{invoice.Title}</Text>
        <Text className='invoiceTxt' pdfMode={pdfMode} value={invoice.Company_Name}>{invoice.Company_Name}</Text>
        <Text className='invoiceTxt' pdfMode={pdfMode} value={invoice.Address}>{invoice.Address}</Text>
        <Text className='invoiceTxt' pdfMode={pdfMode} value={invoice.City + " " + invoice.State + " " + invoice.Zip}>{invoice.City + " " + invoice.State + " " + invoice.Zip}</Text>

      </View>
      <View className="flex mb-5" pdfMode={pdfMode}>
        <View className="w-40" pdfMode={pdfMode}>
          <Text className="bold" value="Qty" pdfMode={pdfMode} />
        </View>
        <View className="w-60" pdfMode={pdfMode}>
        </View>
      </View>
      <View className="flex mb-5" pdfMode={pdfMode}>
        <View className="w-40" pdfMode={pdfMode}>
          <EditableInput className="bold" value={invoice.invoiceDueDateLabel} onChange={(value) => handleChange('invoiceDueDateLabel', value)} pdfMode={pdfMode} />
        </View>
        <View className="w-60" pdfMode={pdfMode}>
        </View>
      </View>
    </View>
  </View>

  <View className="mt-30 bg-dark flex fs-12 pdfFontSize" pdfMode={pdfMode}>
    <View className="w-5 p-4-8 pdfFontSize" pdfMode={pdfMode}>
      <Text className="white bold" pdfMode={pdfMode}>Qty</Text>
    </View>
    <View className="w-40 p-4-8 pdfFontSize" pdfMode={pdfMode}>
      <Text className="white bold left" pdfMode={pdfMode} >Description of Product or Service</Text>
    </View>
    <View className="w-17 p-4-8 pdfFontSize" pdfMode={pdfMode}>
      <Text className="white bold center" pdfMode={pdfMode} ></Text>
    </View>
    <View className="w-18 p-4-8 pdfFontSize" pdfMode={pdfMode}>
      <Text className="white bold center" pdfMode={pdfMode} >Unit Price</Text>
    </View>
    <View className="w-18 p-4-8 pdfFontSize" pdfMode={pdfMode}>
      <Text className="white bold right" pdfMode={pdfMode} >Amount</Text>
    </View>
  </View>
  {pdfMode ? '' :
    dupewarn ? (     <Alert className="text-center" variant="danger">
      There is a duplicate line item. Line items must be unique.
    </Alert>) : ('')}
  {
    invoice.lineItems.map((lt, i) => (
      pdfMode && lt.ProductType === '' ? (<Text key={i} pdfMode={pdfMode}></Text>) : (
        <View key={i} className="flex rowFocu" pdfMode={pdfMode}>
          {!pdfMode && (lt.ProductType.length < 1 ? ('') : (<ImageModal info={lt} onUpload={(value) => { handleProductLineChange(i, 'file', value) }} />))}

          <View className="w-5 p-4-8 pb-10 pdfFontSize" pdfMode={pdfMode}>
            <EditableInput type="number" aria="Qty" title="Qty" placeholder="Qty" value={lt.quantity} className="dark right text-start" onChange={(value) => handleProductLineChange(i, 'quantity', value)} pdfMode={pdfMode} />
          </View>
          <View className="w-40 p-4-8 pb-10 pdfFontSize" pdfMode={pdfMode}>
            {/* {console.log(lt.description)} */}
            {/* <EditableInput type="select" className="dark"  placeholder="Enter item name/description" value={lts.description} onChange={(value) => handleProductLineChange(i, 'description', value)} pdfMode={pdfMode} /> */}
            <EditableSelect placeholder="Select Product or Service" options={descriptions} onChange={(value) => { handleProductLineChange(i, 'ProductType', value); }} value={lt.ProductType} pdfMode={pdfMode} />
            <EditableInput placeholder="Description" className="dark left noHover invoiceTxt" pdfMode={pdfMode} onChange={(value) => { handleProductLineChange(i, 'ProductDetail', value); }} value={lt.ProductDetail} />
          </View>

          <View className="w-18 p-4-8 pb-10 pdfFontSize" pdfMode={pdfMode}>
            <EditableInput placeholder="" className="dark center noHover" aria="Rate" title="Rate" readOnly={true} value={lt.unit} onChange={(value) => handleProductLineChange(i, 'unit', value)} pdfMode={pdfMode} />
          </View>
          <View className="w-17 p-4-8 pb-10 pdfFontSize" pdfMode={pdfMode}>

            <EditableInput className="dark right center pdfFontSize"
              aria="Price" title="Price"
              placeholder="Rate" readOnly={lt.ProductType === 'Consultant Rate' ? true : false} value={"$" + lt.rate}
              onChange={(value) => handleProductLineChange(i, 'rate', value)}
              onBlur={(value) => handleFormat(i, 'rate', value)}
              pdfMode={pdfMode} >{lt.rate}</EditableInput>
          </View>


          <View className="w-18 p-4-8 pb-10 pdfFontSize" pdfMode={pdfMode}>
            <Text className="dark right" pdfMode={pdfMode} >
              {calculateAmount(lt.quantity, lt.rate)}
            </Text>
          </View>
          {!pdfMode && (<button className="link row__remove" aria-label="Remove Line Item" title="Remove Line Item" onClick={() => handleRemove(i)}>
            <span className="icon icon-remove bg-red"></span>
          </button>)}

        </View>
      ))
    )}

  {/* // ) : ("")} */}
  <View className='row' pdfMode={pdfMode}>
    <View className="col  " pdfMode={pdfMode}>
      <View className="w-70 mt-10" pdfMode={pdfMode}>
        {!pdfMode && (<Button variant="white" className='link bg-white' onClick={() => handleAdd()}>
          <span className="iconAdd icon-add bg-green mr-10"></span>
          Add Line Item
        </Button>)}
      </View>
    </View>
    <View className="col  " pdfMode={pdfMode}>
      <View className="w-25 mt-10 float-end" pdfMode={pdfMode}>

        <Text className=" bold right px-1" pdfMode={pdfMode} >Total</Text>

        <EditableInput className="right totalBox" readOnly={true} value={invoice.subTotal} placeholder="Total" onChange={(value) => handleChange('in', value)} pdfMode={pdfMode} />
      </View>
    </View>
  </View>



  <View className=" text-center left">
    <View className="row">
      <View className="col">
        <View className="" pdfMode={pdfMode}>

          <View className="row justify-content-center text-center">
            <View className="row d-block ">
              <View className="w-50 mt-10 bold locationLable" pdfMode={pdfMode}>
                <Text className="text-center">Locations</Text>
              </View>
              <View className="locationContainer">
                {invoice.locations.map((loc, i) => (

                  <View key={i} className="flex rowFocu " pdfMode={pdfMode}>
                    <View className="flex" pdfMode={pdfMode}>
                      {i + 1}
                      <EditableInput className="dark w-70  center" placeholder="City" value={invoice.locations[i].city} readOnly={false} onChange={(value) => handleLocationChange(i, 'city', value)} pdfMode={pdfMode} />
                      <EditableInput className="dark w-30 flex center" maxLength={2} placeholder="State" value={invoice.locations[i].state} readOnly={false} onChange={(value) => handleLocationChange(i, 'state', value)} pdfMode={pdfMode} />
                    </View>
                    {!pdfMode && (<button className="link row__remove" aria-label="Remove Row" title="Remove Row" onClick={() => handleRemoveLoc(i)}>
                      <span className="iconLoc icon-remove bg-red"></span>
                    </button>)}
                  </View>
                ))
                }

              </View>
              {invoice.locations.length <= 7 ? (!pdfMode && (<View className='row w-50'> <Button variant="white" className='bg-white link' onClick={() => handleAddLoc()}>
                <span className="iconAdd icon-add bg-green mr-10"></span>
                Add Location
              </Button></View>)) : ('')}

            </View>
            <View className=" flex">
              <View className="w-70 mt-10 bold row" pdfMode={pdfMode}>
                <Text className="text-center px-5 locationLable notes ">Notes</Text>
                <EditableTextarea className="w-100  notesBox" rows={3} value={invoice.notes} onChange={(value) => handleChange('notes', value)} pdfMode={pdfMode} />
              </View>
              <View className="w-30 mt-10 bold " pdfMode={pdfMode}>
                <Text className="text-center mb-0 ">Reportable</Text>
                <EditableSelect className="w-100  notesBox" options={reportable} value={invoice.Reportable} onChange={(value) => handleChange('Reportable', value)} pdfMode={pdfMode} />
              </View>
            </View>
          </View>
        </View>
      </View>


    </View>
  </View>
</Page>
{pdfMode ? ('') : (<Container>
  <Row className="justify-content-center mt-3 d-flex">
    <Row>
      {checkForm() ? <p className="text-danger text-center">There must be values in Consultant Invoice, Line Items, and Reportable to Save Draft or Submit Invoice</p> : ''}
    </Row>
    {!btnSelect ? ("") : (<> <Col sm className="justify-content-center d-flex my-2">

      {!pdfMode && <Confirm headingTxt="Save Draft Confrimation" disabled={checkForm()} onConfirm={(e) => handleDraft(e)} bodyMsg={`Confirm you want to save a draft invoice for  ${invoice.Company_Name}.`} action="Save Draft" vari="success" />}

    </Col>
      <Col sm className="justify-content-center d-flex my-2">
        {!pdfMode && <Confirm headingTxt="Submit Invoice Confrimation" disabled={checkForm()} onConfirm={(e) => handleSubmit(e)} bodyMsg={`Confirm you want to submit an invoice for ${invoice.Company_Name}. You will not be able to edit this invoice later. `} action="Submit Invoice" vari="primary" />}
      </Col> </>)}

  </Row>
</Container>)}
</Document>
)}


  </>


  )
}


export default Invoice


export const initialLineItem = {
  quantity: '1',
  ProductType: ' ',
  ProductDetail: '',
  rate: '0.00',
  unit: '',
  amount: ''
}

export const initialInvoice = {
  Address: 'data.Address',
  AltEmail: 'data.fieldData.AltEmail',
  City: 'data.fieldData.City',
  Company_Name: 'data.fieldData.Company_Name',
  CompletedDate: 'data.fieldData.CompletedDate',
  Date: 'data.fieldData.Date',
  Email: 'data.fieldData.Email',
  Intervention: 'data.fieldData.Intervention',
  Name: 'data.fieldData.Name',
  Phone: 'data.fieldData.Phone',
  ProjectName: 'data.fieldData.ProjectName',
  ProjectNumber: 'data.fieldData.ProjectNumber',
  RecordId: 'data.fieldData.RecordId',
  State: 'data.fieldData.State',
  Title: 'data.fieldData.Title',
  Zip: 'data.fieldData.Zip',
  zd_f_Companies: 'data.fieldData.zd_fk_Companies',
  zd_pk_Projects: 'data.fieldData.zd_pk_Projects',
  project: {
    BillRate: ' user[Projects | ConsultantsAssign::BillRate]',
    BillType: ' user[Projects | ConsultantsAssign::BillType]',
    ClientBillRate: ' user[Projects | ConsultantsAssign::ClientBillRate]',
    FullName: ' user[Projects | ConsultantsAssign::FullName]',
    Username: ' user[Projects | ConsultantsAssign::Username]',
    zd_fk_Consultant: ' user[Projects | ConsultantsAssign::zd_fk_Consultant]'
  },
  lineItemDescription: 'Description of  or Service',
  lineItemQuantity: 'Qty',
  lineItemQuantityRate: 'Rate',
  lineItemQuantityUnit: 'Unit',
  lineItemQuantityAmount: 'Amount',
  lineItems: [
    {
       quantity: '1',
       ProductType: '',
       ProductDetail: '',
       rate: '0.00',
       unit: '',
       amount: ''
     }
  ],
  subTotalLabel: 'Sub Total',
  taxLabel: 'Sale Tax (10%)',
  totalLabel: 'TOTAL',
  currency: '$',
  notesLabel: 'Notes',
  notes: 'It was great doing business with you.',
  termLabel: 'Terms & Conditions',
  term: 'Please make the payment by the due date.',
}
export default initialInvoice
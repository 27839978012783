import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/authContext";
import { FetchContext } from "../context/FetchContext";
import { Row, Col, Button, Card, Alert, Modal } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
export default function ProjectCard({ props }) {
    const { authState } = useContext(AuthContext);
    // console.log(props)
    const history = useNavigate();
    useEffect(() => {

    }, [props]);
    // let pdc = props.portalData
    // let consul =   Object.values(pdc).filter(value => {
    //     console.log(value[Projects | ConsultantsAssign::zd_fk_Consultant]);
    //     return value["Projects | ConsultantsAssign::zd_fk_Consultant"] === 8;
    //   });
    // // let consul =   Object.values(pdc).filter(t => t["Projects | ConsultantsAssign::zd_fk_Consultant"] = 8)  
    // console.log(consul)


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    function AlertDismissible({ props }) {

        return (
            <>
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    variant="light"
                >
                    <Alert
                        show={show}
                        variant="light"
                        onClose={() => setShow(false)}
                        dismissible
                        style={{ marginBottom: "0px" }}
                    >
                        <Alert.Heading>Confirm Project Selection</Alert.Heading>
                        <p>
                            You selected {props.fieldData.ProjectName}<br />
                            If this was a mistake, click the "X".
                        </p>

                        <div className="d-flex justify-content-end">
                            <Button
                                onClick={() => goToProject(props)}
                                variant="primary"
                                className="mobbtn"
                            >
                                Go To Project
                            </Button>
                        </div>
                    </Alert>
                </Modal>
                {!show && (
                    <Button className="mobbtn" onClick={() => setShow(true)} variant="danger">
                        Go To Project
                    </Button>
                )}
            </>
        );
    }

    function goToProject(project) {
        const lineItems = [{
            quantity: '1',
            ProductType: '',
            ProductDetail: '',
            rate: '0.00',
            unit: '',
            amount: '',
      
        }]
        // console.log('should redirect to projects')
        // console.log(project.fieldData)
        let proj = Object.assign(Object.assign({}, project.fieldData), { lineItems })
        let consul = project.portalData["Projects | ConsultantsAssign"]
        // console.log(consul)
        // console.log(authState.userInfo.zd_pk_Consultants)
        let rt = Object.values(consul).filter((value, i) => value["Projects | ConsultantsAssign::zd_fk_Consultant"] === authState.userInfo.zd_pk_Consultants);
        // console.log(rt)
        let rateInfo = {
            billRate: rt[0]["Projects | ConsultantsAssign::BillRate"],
            billType: rt[0]["Projects | ConsultantsAssign::BillType"],
            fullName: rt[0]["Projects | ConsultantsAssign::FullName"],
            username: rt[0]["Projects | ConsultantsAssign::Username"],
            userID: rt[0]["Projects | ConsultantsAssign::zd_fk_Consultant"],
        }
        // console.log(rateInfo)
        // let locations = [ 'dallas','ft worth', 'frisco', 'lewisville', 'carrollton' , 'mckinney'   ]
        let locations = []
        let proj2 = Object.assign(Object.assign({}, proj), { rateInfo })
        let proj3 = Object.assign(Object.assign({}, proj2), {locations})
        history("/project", { state: { proj3 } })
    }

    return (
        <>
            <Row className="justify-content-center my-2">

                < Card style={{ width: '35rem' }}>
                    <Card.Body>
                        <Card.Title>{props.fieldData.ProjectName}</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">{props.fieldData.Company_Name}</Card.Subtitle>
                        <Card.Text className="mb-1" >
                            {props.fieldData.Address}
                        </Card.Text>
                        <br />
                        <Card.Text className="mb-1">
                            {props.fieldData.City + " " + props.fieldData.State + ", " + props.fieldData.Zip}
                        </Card.Text>
                        <br />
                        <Card.Text>
                            {props.fieldData.Email}
                        </Card.Text>
                        <br />
                        < AlertDismissible props={props} />
                    </Card.Body>
                </Card>
            </Row>
        </>
    )
}
import { Accordion, Table } from 'react-bootstrap';


function AllCollapseExample(props) {
  return (<>
    <Accordion defaultActiveKey={['0']} key={props.id}  >
      <Accordion.Item eventKey={props.id} >
        <Accordion.Header  key={props.id} > <div className='col border-color-red'> {props.name} </div>
          {props.status ? 
          <div className='col'  style={{ width: 'max-content', float: 'right', textAlign: "right", paddingRight: '10px'   }}>
            <p >{props.status + ":" } </p> 
            <p style={{     }} >{"$" +   parseFloat(props.payDue).toFixed(2)  } </p>
          </div>
            : ''}
        </Accordion.Header>
        <Accordion.Body>
          {props.comp}
          {props.pays ? (props.pays.length > 0 ? (<Table striped bordered hover>
            <thead className='text-center'>
              <tr>
                <th>Date Paid</th>
                <th>Amount Paid</th>
                <th>Pay Type</th>
              </tr>
            </thead>
            <tbody className='text-center'>
              <tr>
                <td>{props.pays[0].datePaid}</td>
                <td>${parseInt(props.pays[0].pay).toFixed(2)}</td>
                <td>{props.pays[0].payType}</td>

              </tr>

            </tbody>
          </Table>) : ('')) : ('')}

        </Accordion.Body>
      </Accordion.Item>
    </Accordion>

  </>
  );
}

export default AllCollapseExample;
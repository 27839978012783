 
import Styles from "./styles"
const compose = (classes) => {
    const css = {};
    const classesArray = classes.replace(/\s+/g, ' ').split(' ');
    classesArray.forEach((className) => {
        if (typeof Styles[className] !== undefined) {
            Object.assign(css, Styles[className]);
        }
    });
    return css;
};
export default  compose;

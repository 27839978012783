import React, { useContext, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Row, Col, Button, Form, InputGroup, Image, Spinner } from "react-bootstrap";
import Logo from "../pics/LRI.jpg"
import { AuthContext } from "../context/authContext";
import { FetchContext } from "../context/FetchContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";

export default function Login() {
  const authContext = useContext(AuthContext);
  const fetchContext = useContext(FetchContext);
  const { authState } = useContext(AuthContext);
  const [loading, setIsLoading] = useState(false)
  const history = useNavigate();
  const [showPass, setShowPass] = useState(false);
  const [emailText, setEmailText] = useState(false);
  const [passText, setPassText] = useState(false);
  const [redirectOnLogin, setRedirectOnLogin] = useState(false);
  const [formObject, setFormObject] = useState({
    username: "",
    password: "",
  });
  function handleInputChange(event) {

    const { name, value } = event.target;
    setEmailText(false);
    setPassText(false);
    setFormObject({ ...formObject, [name]: value });
  }

  useEffect(() => {
    checkForm();
  }, [checkForm, formObject, redirectOnLogin]);


  const [formFull, setFormFull] = useState(true);

  // function checkAuth() {
  //   console.log(authState.isAuthenticated)
  //   console.log(authState.userInfo === undefined)

  //   if (authState.userInfo === undefined) {
  //     history("/login")
  //   }
  // }
  // checkAuth()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function checkForm() {


    if (
      formObject.username.length > 3 &&
      formObject.password.length > 5

    ) {
      setFormFull(true);
    } else {
      setFormFull(false);
    }
  }
  async function handleSubmit(e) {
    setIsLoading(true)
    e.preventDefault();
    e.stopPropagation();
    try {
      const { data } = await fetchContext.publicAxios.post("login", formObject);
 
      if (data.message === "Incorrect Username") {
        setIsLoading(false)
        setEmailText(true);
      } else if (data.message === "Incorrect Password") {

        setIsLoading(false)
        setPassText(true);
      } else {
        authContext.setAuthState(data);
        // window.location.reload(false);

        if (data.userInfo.firstLogin === 1) {
          setIsLoading(false)
          history("/setpassword")
        } else {
          setRedirectOnLogin(true);
          setIsLoading(false)
          history("/projects")
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  function passview(e) {

    if (showPass) {

      setShowPass(false)
      document.getElementById(e).type = "password"
    } else {
      setShowPass(true)
      document.getElementById(e).type = "text"
    }
  }

  return (

    <>
      {redirectOnLogin && <Navigate to="/dashboard" />}
      <Row

      >
        <div className="Auth-form-container">
          <form className="Auth-form" onSubmit={handleSubmit}>
            <div className="Auth-form-content text-center">
              <Image src={Logo} style={{ maxWidth: "55%" }} />
              <h3 className="Auth-form-title">Sign In</h3>
              <div className="form-group mt-3">
                <label>Username</label>
                <input
                  type="username"
                  name="username"
                  className="form-control mt-1"
                  placeholder="Enter username"
                  onChange={(e) => handleInputChange(e)}
                />
                {emailText ? (<Form.Text className="text-danger">
                  Could not find that username.
                </Form.Text>) : ("")}
              </div>
              <div className="form-group mt-3">
                <label>Password</label>
                <InputGroup className="">
                  <Form.Control
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Enter Password"
                    aria-label="Enter Password"
                    onChange={(e) => handleInputChange(e)}
                    aria-describedby="basic-addon2"
                  />
                  <Button variant="outline-secondary" id="button-addon2" onClick={(e) => passview("password")}>
                    {showPass ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
                  </Button>
                </InputGroup>
                {passText ? (<Form.Text className="text-danger">
                  The wrong password was entered.
                </Form.Text>) : ("")}
              </div>
              <div className="d-grid gap-2 mt-3">
                <button type="submit" className="btn text-white" style={{ backgroundColor: "#023650" }} disabled={formFull ? false : true}>
                  <>                  {loading ? (<>


                    <Spinner size="sm" animation="border" variant="light" />


                  </>) : ('Submit')}
                  </>

                </button>
              </div>
              <p className="forgot-password text-right mt-2">
                <a href="/forgotpassword">Forgot password?</a>
              </p>
            </div>
          </form>
        </div>
      </Row>
    </>
  );
}

import React, {  useContext } from "react";

import "./reset.css";
 
import "bootstrap/dist/css/bootstrap.min.css";
import 'font-awesome/css/font-awesome.min.css'
import "./App.css";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import { AuthProvider, AuthContext } from "./context/authContext";
import { FetchProvider } from "./context/FetchContext";
import AppShell from "./AppShell";

import Login from "./pages/Login";
import Projects from "./pages/Projects";
import Password from "./pages/SetPassword";
import Project from "./pages/Project";
import ForgotPass from "./pages/ForgotPassword"
import Invoices from "./pages/Invoices";



function RequireAuth({ children }) {
  const { authState } = useContext(AuthContext);




  return authState.isAuthenticated === true ? children : <Navigate to="/" />;
};


function  App(){
  return (
    <Router>
      <FetchProvider>
        <AuthProvider>
          <AppShell>
            <div>
              <Routes>
                <Route exact path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/home" element={<Login />} />
                <Route path="/forgotpassword" element={<ForgotPass />} />
                <Route
                  path="/projects/*"
                  element={
                    <RequireAuth>
                      <Projects />
                    </RequireAuth>} />
                <Route
                  path="/setpassword/*"
                  element={
                    <RequireAuth>
                      <Password />
                    </RequireAuth>} />
                <Route
                  path="/project/*"
                  element={
                    <RequireAuth>
                      <Project />
                    </RequireAuth>} />
                <Route
                  path="/invoices/*"
                  element={
                    <RequireAuth>
                      <Invoices />
                    </RequireAuth>} />
              </Routes>
            </div>
          </AppShell>
        </AuthProvider>
      </FetchProvider>
    </Router>
  );
}

export default App;

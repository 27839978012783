import React from "react";
import { Button, Container, Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { Navigate, useNavigate, useLocation } from "react-router-dom";
function AlertMsg({ vari, message, title, link, setCancel, setLoad, showButton }) {
    const nav = useNavigate();
   
    function handleClick() {
        nav(`/${link}`)
    }
    return (
        <>
            <Container className='myAlert'>
                <Alert key={vari} variant={vari} className='text-center'>
                    
                    <h5>{title}</h5>
                    <Row>
                    <p>{message}  </p>
                    </Row>
                    {showButton ? (<>  <Button onClick={() => handleClick()}>Go to {link}</Button>
                        <Button className="m-2" variant='danger' onClick={() => { setCancel(); setLoad() }} >Go Back</Button></>) : ('')}

                </Alert>
            </Container>
        </>
    );
}

export default AlertMsg;
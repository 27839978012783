import React, { useContext } from "react";
import Logo from "../../src/pics/LRI.jpg";
import { Navbar, Nav, Image, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthContext } from "../context/authContext";

import { useNavigate, useLocation } from "react-router-dom";

const AlamarkNav = () => {
    const history = useNavigate();
    const loc = useLocation()
    const auth = useContext(AuthContext);
    const { authState } = auth;

    function goToLink(link) {
        // console.log(link)
        history(`/${link}`)
    }
    return (
        <>
            <Navbar
                collapseOnSelect
                expand="lg"
                className="px-3"
                variant="light"
                bg="white"
            >
                <Navbar.Brand>
                    <Image src={Logo} style={{ height: '50px', marginLeft: "10px" }} fluid />
                </Navbar.Brand>


                {authState.isAuthenticated ? <>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                        <Nav>
                   
                            {loc.pathname === '/projects' ? ('') : <Nav.Link   >
                                <button className="btn mobbtn" onClick={() => goToLink("projects")}>
                                    Projects
                                </button>
                            </Nav.Link>}
                            {loc.pathname === '/invoices' ? ('') :
                                <Nav.Link   >
                                    <button className="btn mobbtn" onClick={() => goToLink("invoices")}>
                                        Invoices
                                    </button>
                                </Nav.Link>}
                            <Nav.Link   >
                                <button className="btn text-white mobbtn" style={{ backgroundColor: "#961d1d" }} onClick={auth.logout}>
                                    Log Out
                                </button>
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </> : (
                    ""
                )}


            </Navbar>
        </>
    );
}

export default AlamarkNav;

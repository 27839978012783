import React, { FC } from 'react'
import { Text } from '@react-pdf/renderer'
import compose from '../styles/compose'

const EditableInput = ({ className, placeholder,  value = '', onChange, pdfMode,type, readOnly, maxLength, pattern,onBlur, aria,title})  => {
  // console.log(pdfMode)
  // console.log(className)
  // console.log(value)
 

    return (<>
      {pdfMode ? (<Text style={ compose('span ' + (className ? className : ''))}>{value}</Text>) : (
      <input type={type ? 'number' : 'text'}   pattern={pattern ? pattern : ''}  maxLength={maxLength > 0 ? maxLength : undefined } 
      readOnly={readOnly}
      className={'input ' + (className ? className : '')} 
      value={value}placeholder={placeholder || ''} 
      title={title}
      aria-label={aria}
      onBlur={onBlur ? (e) => onBlur(e.target.value) : undefined }  
      onChange={onChange ? (e) => onChange(e.target.value) : undefined}/>
      
      )}
    </>);
};
 export default EditableInput
import React, { useContext, useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Row, Col, Button, Form, InputGroup, Image, Spinner } from "react-bootstrap";
import Logo from "../pics/LRI.jpg"
import { AuthContext } from "../context/authContext";
import { FetchContext } from "../context/FetchContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";

export default function Login() {
    const authContext = useContext(AuthContext);
    const fetchContext = useContext(FetchContext);
    const { authState } = useContext(AuthContext);
    const [loading, setIsLoading] = useState(false)
    const history = useNavigate();

    const [emailText, setEmailText] = useState(false);

    const [redirectOnLogin, setRedirectOnLogin] = useState(false);
    const [formObject, setFormObject] = useState({
        username: ""
    });
    const [success, setSuccess] = useState(false)
    function handleInputChange(event) {

        const { name, value } = event.target;
        setEmailText(false);

        setFormObject({ ...formObject, [name]: value });
    }

    useEffect(() => {
        checkForm();
    }, [checkForm, formObject, redirectOnLogin]);


    const [formFull, setFormFull] = useState(true);



    // eslint-disable-next-line react-hooks/exhaustive-deps
    function checkForm() {
        if (
            formObject.username.length > 3
        ) {
            setFormFull(true);
        } else {
            setFormFull(false);
        }
    }
    async function handleSubmit(e) {
        setIsLoading(true)
        e.preventDefault();
        e.stopPropagation();
        try {
            // console.log("formObject");
            // console.log(formObject);
            // console.log("loggin in");
            const { data } = await fetchContext.publicAxios.post("forgotpassword", formObject);
            // console.log(data)
            if (data.message === "No User Found") {
                setIsLoading(false)
                setEmailText(true);
            } else if (data.message === 'Success') {
                setSuccess(true)
            }
        } catch (error) {
            console.log(error.message);
        }
    }


    return (

        <>
            <Row>
                <div className="Auth-form-container">
                    <form className="Auth-form" onSubmit={handleSubmit}>
                        <div className="Auth-form-content text-center">
                        <Image src={Logo} style={{ maxWidth: "55%" }} />
                            {success ? (<>
                                <h3 className="Auth-form-title">Password Reset!</h3>
                                <div className="form-group mt-3">
                                    <p>A new password was emailed to the email we have on record.</p>
                                    <p className="text-center">
                                    <Button onClick={() => {history("/login") }}>Go to Login</Button></p>
                                </div>

                            </>) : <>
                                <h3 className="Auth-form-title">Forgot Password</h3>
                                <div className="form-group mt-3">
                                    <label>Username</label>
                                    <input
                                        type="username"
                                        name="username"
                                        className="form-control mt-1"
                                        placeholder="Enter username"
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                    {emailText ? (<Form.Text className="text-danger">
                                        Could not find that username.
                                    </Form.Text>) : ("")}
                                </div>

                                <div className="d-grid gap-2 mt-3">
                                    <button type="submit" className="btn text-white" style={{ backgroundColor: "#023650" }} disabled={formFull ? false : true}>
                                        <>                  {loading ? (<>


                                            <Spinner size="sm" animation="border" variant="light" />


                                        </>) : ('Submit')}
                                        </>

                                    </button>
                                </div></>}


                        </div>
                    </form>
                </div>
            </Row>
        </>
    );
}

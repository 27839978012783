import { React, useEffect, useState, useContext } from "react";
import { initialInvoice, initialLineItem } from "../data/initialData";
import EditableInput from "./EditableInput";
import { Row, Button, Col, Container, Alert, Spinner
 } from "react-bootstrap";
import Document from "./Document";
import Page from "./Page";
import View from "./View";
import Text from "./Text";
import DownloadPDF from './DownloadPDF'
import EditableTextarea from './EditableTextarea'
import EditableSelect from './EditableSelect'
import ImageModal from "./AddReceipt";
import { FetchContext } from "../context/FetchContext";
import { AuthContext } from "../context/authContext";
import AlertMsg from "./PopUp";
import Confirm from "./Confirm";
import { pdf } from "@react-pdf/renderer";

const Invoice = ({ cons, data, pdfMode, onChange, btnSelect, editable }) => {
  const [itemDelete, setItemsToDelete] = useState([])
  const [locDelete, setLocsToDelete] = useState([])
  const auth = useContext(AuthContext);

  const [consult, setCons] = useState(cons)
  const [alertShow, setAlertShow] = useState(false)
  const [alertMsg, setAlertMsg] = useState('')
  const [alertTitle, setAlertTitle] = useState('')
  const [alertVari, setAlertVari] = useState('')
  const fetchContext = useContext(FetchContext);
  const [isLoading, setIsLoading] = useState(true)
  const [subTotal, setSubTotal] = useState();
  const [file, setFile] = useState([]);
  const descriptions = [
    { value: "", text: 'Select Product or Service' },
    { value: "Airfare", text: 'Airfare' },
    { value: "Airport Parking", text: 'Airport Parking' },
    { value: "Consultant Rate", text: 'Consultant Rate' },
    { value: "Fuel for Rental Car", text: 'Fuel for Rental Car' },
    { value: "Ground Transportation", text: 'Ground Transportation' },
    { value: "Hotel", text: 'Hotel' },
    { value: "Mileage", text: 'Mileage' },
    { value: "Per Diem", text: 'Per Diem' },
    { value: "Rental Car", text: 'Rental Car' },
    { value: "Travel Fee", text: 'Travel Fee' },
  ]
  const reportable = [
    { value: '', text: "Select" },
    { value: "Reportable", text: "Yes" },
    { value: "Non-Reportable", text: "No" }
  ]
  const [dupewarn, setDupeWarn] = useState(false)
  const [lineItems, setLineItems] = useState([]);
  const [invoice, setInvoice] = useState(data ? Object.assign({}, data) : Object.assign({}, initialInvoice));
  const [refresh, setRefresh] = useState(false)
  function checkForm() {

    if (invoice.lineItems.length >= 1 && invoice.Reportable && invoice.ConsultantInvoice) {

      return false
    }
    else {

      return true
    }
  }
  function setInvoiceFunc() {

    setInvoice(() => data)
    setRefresh(true)
    setIsLoading(false)


  }
  useEffect(() => {
    setRefresh(false)
    setInvoiceFunc()

  }, [refresh, isLoading]);

  // useEffect(() => {
  //   console.log(onChange)
  //   if (onChange) {
  //     onChange(invoice);
  //   }
  // }, [onChange, invoice]);


  useEffect(() => {
    let subTotal = 0;

    if (invoice.lineItems) {
      invoice.lineItems.forEach((lineItem) => {
        // console.log(lineItem)
        const quantityNumber = parseFloat(lineItem.quantity);
        const rateNumber = parseFloat(lineItem.rate);
        const amount = quantityNumber && rateNumber ? quantityNumber * rateNumber : 0;
        subTotal += amount;
      });
    }
    subTotal = parseFloat(subTotal).toFixed(2)
    // console.log(subTotal);
    setSubTotal(subTotal);
    setInvoice(Object.assign(Object.assign({}, invoice), { subTotal }))
    checkDupe(invoice)
  }, [isLoading, invoice.lineItems]);

  const handleChange = (name, value) => {
    // console.log(name)
    // console.log(value)
    if (name !== 'lineItems') {
      const newInvoice = Object.assign({}, invoice);
      if (name === 'logoWidth' && typeof value === 'number') {
        newInvoice[name] = value;
      }
      else if (name !== 'logoWidth' && typeof value === 'string') {

        newInvoice[name] = value;
      }
      setInvoice(newInvoice);
      // console.log(invoice)
    }
  };

  const handleProductLineChange = (index, name, value) => {
      console.log(invoice)
    const lineItems = invoice.lineItems.map((lineItem, i) => {
      if (i === index) {

        const newLineItem = Object.assign({}, lineItem);;
        if (name === 'ProductType') {
          newLineItem[name] = value;
          if (value === 'Consultant Rate') {
            newLineItem.rate = invoice["ConsultInv | ConsultantsAssign_Projects::BillRate"].toFixed(2);
            newLineItem.unit = invoice["ConsultInv | ConsultantsAssign_Projects::BillType"];
          } else {
            newLineItem.rate = '0.00';
            newLineItem.unit = '';
          }
        } else if (name === "ProductDetail") {
          newLineItem[name] = value;
        }
        else if (name === 'file') {
          // console.log("FILE SETTING")
          // console.log(value)
          if (value.length === 0) {

            newLineItem.base64Container = '';
            newLineItem.fileName = '';

          }
          else {
            newLineItem.base64Container = value[0].b64;
            newLineItem.fileName = value[0].fileName;
          }
        }
        else {
          let val = value.replace('$', '')
          if (val[val.length - 1] === '.' ||
            (val[val.length - 1] === '0' && val.includes('.'))) {
            newLineItem[name] = val;
          }
          else {
            const n = parseFloat(val);
            newLineItem[name] = (n ? n : 0).toString();

          }
        }
        return newLineItem;
      }
      return Object.assign({}, lineItem);
    });
    setInvoice(Object.assign(Object.assign({}, invoice), { lineItems }));


  };

  const handleFormat = (index, name, value) => {
    // console.log("handleFormat")
    // console.log(index)
    // console.log(name)
    // console.log(value)

    if (editable) {
      let val = value.replace('$', '')
      const lineItems = invoice.lineItems.map((lineItem, i) => {
        if (name === 'rate' && i === index && val.length > 0) {
          // console.log(lineItem)
          const newLineItem = Object.assign({}, lineItem);


          newLineItem[name] = parseFloat(val).toFixed(2)

          return newLineItem;
        }
        return Object.assign({}, lineItem);
      });
      setInvoice(Object.assign(Object.assign({}, invoice), { lineItems }));
      // console.log(invoice)
    }
  };



  const handleRemoveLoc = (i, value) => {
    const locations = invoice.locations.filter((loc, index) => index !== i);
    setInvoice(Object.assign(Object.assign({}, invoice), { locations }))
    const delLoc = invoice.locations.filter((loc, index) => index === i);

    if (delLoc[0].recordID) {
      setLocsToDelete([...locDelete, delLoc[0].recordID])
    }

  };
  const handleRemove = (i) => {

    const lineItems = invoice.lineItems.filter((lineItem, index) => index !== i);
    const delItem = invoice.lineItems.filter((lineItem, index) => index === i);
    if (delItem[0].recordID) {
      setItemsToDelete([...itemDelete, delItem[0].recordID])
    }
    setLineItems(lineItems)
    setInvoice(Object.assign(Object.assign({}, invoice), { lineItems }))
  };
  function handleAdd() {
    // console.log(invoice.lineItems)
    const lineItems = [...invoice.lineItems, initialInvoice.lineItems[0]];
    setInvoice(Object.assign(Object.assign({}, invoice), { lineItems }));

  };
  function handleLocationChange(index, name, value) {
    const locations = invoice.locations.map((loc, i) => {
      const newLoc = Object.assign({}, loc);;
      if (i === index) {
        newLoc[name] = value;

        return newLoc;
      }
      return loc;
    });
    setInvoice(Object.assign(Object.assign({}, invoice), { locations }));
  };

  function handleAddLoc() {
    const locations = [...invoice.locations, { "city": '', "state": '' }]
    setInvoice(Object.assign(Object.assign({}, invoice), { locations }))
  }
  const calculateAmount = (quantity, rate) => {
    const quantityNumber = parseFloat(quantity);
    const rateNumber = parseFloat(rate);
    const amount = quantityNumber && rateNumber ? quantityNumber * rateNumber : 0;
    return amount.toFixed(2);
  };
  async function handleDraft(e) {
 
    setIsLoading(true);
    setAlertShow(true)
    setAlertMsg('Your invoice is being saved.');
    setAlertTitle('Saving! Please do not leave or refresh the page.');
    setAlertVari('alert');
    try {
      await fetchContext.authAxios.post("/savedraft", { invoice, itemDelete, locDelete }).then((res) => {
        if (res.data.message === 'Session Expired') {
          auth.logout();
        }
        if (res.data.message === 'Success') {
          setItemsToDelete([]);
          setLocsToDelete([]);
          setAlertMsg('Your invoice has successfully saved.');
          setAlertTitle('Success!');
          setAlertVari('primary');
          setAlertShow(true);
          setTimeout(() => {
            setAlertShow(false);
          }, 3000);
          // No need to reload the page
          setIsLoading(false); // Reset loading state
          setRefresh(true); // Trigger re-render if necessary
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  // async function handleDraft(e) {
  //   //console.log("savedraft")
  //   e.preventDefault();
  //   // console.log(invoice)
  //   setIsLoading(true)
  //  // console.log(isLoading)
  //   try {
  //     await fetchContext.authAxios.post("/savedraft", { invoice, itemDelete, locDelete }).then((res) => {
  //       // console.log(res.data)
  //       if (res.data.message === 'Session Expired') {
  //         auth.logout()
  //       }
  //       if (res.data.message === 'Success') {
  //         setItemsToDelete([])
  //         setLocsToDelete([])
  //         setAlertMsg('Your invoice has succesfully saved.')
  //         setAlertTitle('Success!')
  //         setAlertVari('primary')
  //         setAlertShow(true)
  //         setTimeout(() => {
  //           setAlertShow(false)
  //         }, 3000);
  //         setIsLoading(true)
  //         setRefresh(true)
  //         window.location.reload(false)
  //       }
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }

  // }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    setAlertShow(true)
    setAlertMsg('Your invoice is being saved.');
    setAlertTitle('Saving! Please do not leave or refresh the page.');
    setAlertVari('alert');
    try {
      await fetchContext.authAxios.post("/submitinvoice", { invoice, itemDelete, locDelete }).then((res) => {
        // console.log(res.data)
        if (res.data.message === 'Session Expired') {
          auth.logout()
        }
        if (res.data.message === 'Success') {
          setItemsToDelete([])
          setLocsToDelete([])
          setAlertMsg('Your invoice has succesfully saved.')
          setAlertTitle('Success!')
          setAlertVari('primary')
          setAlertShow(true)
          setTimeout(() => {
            setAlertShow(false)
          }, 3000);
          setIsLoading(false)
          setRefresh(true)

          window.location.reload(false)
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  function checkDupe(ie) {
    // console.log("checking dupe")
    var result = Object.values(ie.lineItems.reduce((c, v) => {
      // if (v.fileName?.length >= 1) {

      //   let k = v.ProductType + '-' + v.fileName;
      //   c[k] = c[k] || [];
      //   c[k].push(v);
      //   return c;
      // }
      // else {

      if (v.ProductType.length > 0) {
        let k = v.ProductDetail.trim();
        c[k] = c[k] || [];
        c[k].push(v);
        return c;
      }

      return c;
      // }
    }, {})).reduce((c, v) => v.length > 1 ? c.concat(v) : c, []);
    if (result.length >= 1) { setDupeWarn(true) }
    else { setDupeWarn(false) }

    //  console.log(result);
  }

  return (<>
    {isLoading ? <>
                       <Row> <p className="pl-3 text-center">Saving invoice.</p></Row>
                       <Row> <Spinner className="mx-auto" animation="border" variant="danger" /></Row>
                    </> : (

      <>
        <Document pdfMode={pdfMode} className="app ">

          <Page className="invoice-wrapper pdfPadding" pdfMode={pdfMode}>
            <View className="flex" pdfMode={pdfMode}>
              <View className="w-50" pdfMode={pdfMode}>

                <Text className='invoiceLRI' pdfMode={pdfMode} value={consult.NameFirst + " " + consult.NameLast} >{consult.NameFirst + " " + consult.NameLast}</Text>
                <Text className='invoiceTxt' pdfMode={pdfMode} value={consult.Address} >{consult.Company}</Text>
                <Text className='invoiceTxt' pdfMode={pdfMode} value={consult.Address} >{consult.Address}</Text>
                <Text className='invoiceTxt' pdfMode={pdfMode} value={consult.City + " " + consult.St + " " + consult.Zip} >{consult.City + " " + consult.St + ", " + consult.Zip}</Text>
                <Text className='invoiceTxt' pdfMode={pdfMode} value={cons.Phone} >{cons.Phone}</Text>



              </View>
              <View className="w-50" pdfMode={pdfMode}>

                {/* <View className="flex" pdfMode={pdfMode}>
                <View className={pdfMode ? "w-55 float-right pr-5" : "w-55"} pdfMode={pdfMode}>
                  <Text className="bold invoiceTxt" value="Invoice #:" pdfMode={pdfMode} >Consultant Invoice #:</Text>
                </View>
                <View className="w-55" pdfMode={pdfMode}>
                  {editable ?  <EditableInput className="invoiceTxt" value={invoice.ConsultantInvoice}    onChange={(value) => handleChange('ConsultantInvoice', value)} pdfMode={pdfMode}   >{invoice.ConsultantInvoice}</EditableInput> 
                  : 
                  <Text className="invoiceTxt" value={invoice.ConsultantInvoice}    pdfMode={pdfMode}   >{invoice.ConsultantInvoice}</Text>}
                
                </View>
              </View>
              <View className="flex" pdfMode={pdfMode}>
                <View className={pdfMode ? "w-65 float-right pr-5" : "w-65"} pdfMode={pdfMode}>
                  <Text className="bold invoiceTxt" value="Invoice #:" pdfMode={pdfMode} >Invoice #:</Text>
                </View>
                <View className="w-85" pdfMode={pdfMode}>
                  <Text className="invoiceTxt" value={invoice.Invoice_Number} pdfMode={pdfMode} >{invoice.Invoice_Number}</Text>
                </View>
              </View>
              <View className="flex" pdfMode={pdfMode}>
                <View className={pdfMode ? "w-65 float-right pr-5" : "w-65"} pdfMode={pdfMode}>
                  <Text className=" bold invoiceTxt" value={"Invoice Date:"} onChange={(value) => handleChange('invoiceDateLabel', value)} pdfMode={pdfMode} >Invoice Date:</Text>
                </View>
                <View className="w-85" pdfMode={pdfMode}>
                  <Text className="invoiceTxt" value={invoice.Invoice_Date} onChange={(value) => handleChange('invoiceDueDateLabel', value)} pdfMode={pdfMode} >{invoice.Invoice_Date}</Text>
                </View>
              </View>
              <View className="flex" pdfMode={pdfMode}>
                <View className={pdfMode ? "w-65 float-right pr-5" : "w-65"} pdfMode={pdfMode}>
                  <Text className=" bold invoiceTxt" value={"Project Name:"} onChange={(value) => handleChange('invoiceDateLabel', value)} pdfMode={pdfMode} >Project Name:</Text>
                </View>
                <View className="w-85" pdfMode={pdfMode}>
                  <Text className="invoiceTxt" value={invoice.Invoice_Date} onChange={(value) => handleChange('invoiceDueDateLabel', value)} pdfMode={pdfMode} >{invoice.ProjectName}</Text>
                </View>
              </View>
              <View className="flex mb-5" pdfMode={pdfMode}>
                <View className={pdfMode ? "w-65 float-right pr-5" : "w-65"} pdfMode={pdfMode}>
                  <Text className=" bold invoiceTxt" value={"Invoice Date:"} onChange={(value) => handleChange('invoiceDateLabel', value)} pdfMode={pdfMode} >Project #:</Text>
                </View>
                <View className="w-85" pdfMode={pdfMode}>
                  <Text className="invoiceTxt" value={invoice.Invoice_Date} onChange={(value) => handleChange('invoiceDueDateLabel', value)} pdfMode={pdfMode} >{invoice["ConsultInv | Projects::ProjectNumber"]}</Text>
                </View>
              </View>
            </View>
          </View> */}
                <View className="flex" pdfMode={pdfMode}>
                  <View className=" col w-100 " pdfMode={pdfMode}>
                    <View className='row flex mb-0' pdfMode={pdfMode}>
                      <View className="col text-end " pdfMode={pdfMode}>    <Text className="invoiceTxt" value="Invoice #:" pdfMode={pdfMode} >{'Consultant Invoice #: '}</Text></View>
                      <View className="col   text-start " pdfMode={pdfMode}>    <Text className="invoiceTxt" value="Invoice #:" pdfMode={pdfMode} >{invoice.ConsultantInvoice}</Text></View>
                    </View>
                    <View className='row flex ' pdfMode={pdfMode}>
                      <View className="col  text-end " pdfMode={pdfMode}>    <Text className="invoiceTxt" value="Invoice #:" pdfMode={pdfMode} >Invoice #:</Text></View>
                      <View className="col  text-start " pdfMode={pdfMode}>    <Text className="invoiceTxt" value="Invoice #:" pdfMode={pdfMode} >{invoice.Invoice_Number}</Text></View>
                    </View>
                    <View className='row flex' pdfMode={pdfMode}>
                      <View className="col  text-end  " pdfMode={pdfMode}>    <Text className="invoiceTxt" value="Invoice #:" pdfMode={pdfMode} >Invoice Date: </Text></View>
                      <View className="col  text-start  " pdfMode={pdfMode}>    <Text className="invoiceTxt" value="Invoice #:" pdfMode={pdfMode} >{invoice.Invoice_Date}</Text></View>
                    </View>
                    <View className='row flex ' pdfMode={pdfMode}>
                      <View className="col  text-end " pdfMode={pdfMode}>    <Text className="invoiceTxt" value="Invoice #:" pdfMode={pdfMode} >Project Name: </Text></View>
                      <View className="col   text-start " pdfMode={pdfMode}>    <Text className="invoiceTxt" value="Invoice #:" pdfMode={pdfMode} >{invoice.ProjectName}</Text></View>
                    </View>
                    <View className='row flex' pdfMode={pdfMode}>
                      <View className="col  text-end " pdfMode={pdfMode}>    <Text className="invoiceTxt" value="Invoice #:" pdfMode={pdfMode} >Project #:</Text></View>
                      <View className="col  text-start " pdfMode={pdfMode}>    <Text className="invoiceTxt" value="Invoice #:" pdfMode={pdfMode} >{invoice['ConsultInv | Projects::ProjectNumber']}</Text></View>
                    </View>



                  </View>
                </View>
              </View>
            </View>
            <View className="flex mt-40" pdfMode={pdfMode}>
              <View className="w-50" pdfMode={pdfMode}>
                <Text className='invoiceLRI' pdfMode={pdfMode} value="LRI Consulting Services, Inc." >LRI Consulting Services, Inc.</Text>
                <Text className='invoiceTxt' pdfMode={pdfMode} value="P.O. Box 1529" >P.O. Box 1529</Text>
                <Text className='invoiceTxt' pdfMode={pdfMode} value="Broken Arrow, OK 74013" >Broken Arrow OK, 74013</Text>
                <Text className='invoiceTxt' pdfMode={pdfMode} value="(918) 455-9995" >(918) 455-9995</Text>
              </View>

              <View className="col w-50 text-center invnumCon" pdfMode={pdfMode} >
                <View className={pdfMode ? '  invoiceBox float-end  ' : '  float-end invoiceBox'} pdfMode={pdfMode}>

                  <Text className='invoiceBoxTitle invoiceLRI ' value="Client Bill To:" pdfMode={pdfMode} >Client Bill To:</Text>

                  <Text className='invoiceTxt' pdfMode={pdfMode} value={invoice.Name}>{invoice.Name}</Text>
                  <Text className='invoiceTxt' pdfMode={pdfMode} value={invoice.Title}>{invoice.Title}</Text>
                  <Text className='invoiceTxt' pdfMode={pdfMode} value={invoice.Company_Name}>{invoice.Company_Name}</Text>
                  <Text className='invoiceTxt' pdfMode={pdfMode} value={invoice.Address}>{invoice.Address}</Text>
                  <Text className='invoiceTxt' pdfMode={pdfMode} value={invoice.City + " " + invoice.State + " " + invoice.Zip}>{invoice.City + " " + invoice.State + " " + invoice.Zip}</Text>

                </View>
              </View>


            </View>

            <View className="mt-30 bg-dark flex fs-12 " pdfMode={pdfMode}>
              <View className="w-7 p-4-8" pdfMode={pdfMode}>
                <Text className="white bold invoiceTxt" pdfMode={pdfMode}>Qty</Text>
              </View>
              <View className="w-40 p-4-8 invoiceTxt" pdfMode={pdfMode}>
                <Text className="white bold left " pdfMode={pdfMode} >Description of Product or Service</Text>
              </View>
              <View className="w-17 p-4-8" pdfMode={pdfMode}>
                <Text className="white bold center invoiceTxt" pdfMode={pdfMode} ></Text>
              </View>
              <View className="w-18 p-4-8" pdfMode={pdfMode}>
                <Text className="white bold center invoiceTxt" pdfMode={pdfMode} >Unit Price</Text>
              </View>
              <View className="w-18 p-4-8" pdfMode={pdfMode}>
                <Text className="white bold  invoiceTxt" pdfMode={pdfMode} >Amount</Text>
              </View>
            </View>
            {pdfMode ? '' :
              dupewarn ? (<Alert className="text-center" variant="danger">
                There is a duplicate line item. Line items must be unique.
              </Alert>) : ('')}
            {
              invoice.lineItems.length !== undefined ? (invoice.lineItems.map((lt, i) => (
                pdfMode && lt.ProductType === '' ? (<Text key={i} pdfMode={pdfMode}></Text>) : (
                  <View key={i} className="flex rowFocu" pdfMode={pdfMode}>
                    {!pdfMode && (editable && lt.ProductType.length > 0 ? <ImageModal info={lt} onUpload={(value) => { setFile(value); handleProductLineChange(i, 'file', value) }} /> : '')}

                    <View className={pdfMode ? 'w-5 text-start' : ' w-5 p-4-8 pb-5'} pdfMode={pdfMode}>

                      <EditableInput type="number" placeholder="Qty" value={lt.quantity} readOnly={editable ? false : true} className="dark right text-start invoiceTxt" onChange={(value) => handleProductLineChange(i, 'quantity', value)} pdfMode={pdfMode} />
                    </View>
                    <View className={pdfMode ? 'w-35' : 'w-40 p-4-8 pb-5'} pdfMode={pdfMode}>

                      {editable ? <>
                        <EditableSelect placeholder="Select Product or Service" options={descriptions} readOnly={editable ? false : true} onChange={(value) => { handleProductLineChange(i, 'ProductType', value); }} value={lt.ProductType} pdfMode={pdfMode} />
                        <EditableInput placeholder="Description" value={lt.ProductDetail} className="dark left noHover invoiceTxt" pdfMode={pdfMode} onChange={(value) => { handleProductLineChange(i, 'ProductDetail', value); }} />
                      </> :
                        <>
                          <EditableInput placeholder="" className="dark left noHover invoiceTxt mb-0" readOnly={true} value={lt.ProductType} pdfMode={pdfMode} />
                          {lt.ProductDetail.length > 0 ? <EditableInput placeholder="" className="dark left noHover invoiceTxt" readOnly={true} value={lt.ProductDetail} pdfMode={pdfMode} />
                            : ''}
                        </>
                      }

                    </View>

                    <View className={pdfMode ? 'w-17 text-start' : 'w-18 p-4-8 pb-5'} pdfMode={pdfMode}>
                      <EditableInput placeholder="" className="dark center noHover invoiceTxt" readOnly={true} value={lt.ProductType === 'Consultant Rate' ? invoice["ConsultInv | ConsultantsAssign_Projects::BillType"] : lt.unit} onChange={(value) => handleProductLineChange(i, 'unit', value)} pdfMode={pdfMode} />
                    </View>
                    <View className={pdfMode ? 'w-17' : 'w-17 p-4-8 pb-5'} pdfMode={pdfMode}>


                      <EditableInput className="dark right noHover center pdfFontSize"
                        placeholder="Rate" readOnly={lt.ProductType === 'Consultant Rate' || editable === false ? true : false} value={'$' + lt.rate}
                        onChange={(value) => handleProductLineChange(i, 'rate', value)}
                        onBlur={(value) => handleFormat(i, 'rate', value)}
                        pdfMode={pdfMode} >${lt.rate}</EditableInput>
                    </View>


                    <View className={pdfMode ? 'w-17' : 'w-17 p-4-8 pb-5'} pdfMode={pdfMode}>
                      <Text className="dark right invoiceTxt" pdfMode={pdfMode} >
                        ${calculateAmount(lt.quantity, lt.rate)}
                      </Text>
                    </View>
                    {!pdfMode && (editable ? <button className="link row__remove" aria-label="Remove Row" title="Remove Row" onClick={() => handleRemove(i)}>
                      <span className="icon icon-remove bg-red"></span>
                    </button> : '')}

                  </View>
                )))) : ("")}




            <View className='row' pdfMode={pdfMode}>
              <View className="col  " pdfMode={pdfMode}>
                <View className="w-50 mt-10" pdfMode={pdfMode}>

                  {!pdfMode && (editable ? (<View className='row'> <Button variant="white" className=" ms-2 link" onClick={() => handleAdd()}>
                    <span className="iconAdd icon-add bg-green mr-10"></span>
                    Add Line Item
                  </Button></View>) : ('')

                  )
                  }
                </View>
              </View>
              <View className={pdfMode ? 'fs-12 ' : 'col'} pdfMode={pdfMode}>
                <View className="mt-10 float-end" pdfMode={pdfMode}>

                  <Text className=" bold right px-1" pdfMode={pdfMode} >Total</Text>

                  <Text type="number" className="right totalBox" readOnly={true} value={invoice.subTotal} placeholder="Total" onChange={(value) => handleChange('in', value)} pdfMode={pdfMode} >${invoice.subTotal}</Text>
                </View>
              </View>
            </View>



            <View className="row">

              <View className={pdfMode ? 'fs-12 ' : 'col'} pdfMode={pdfMode}>
                <View className="" pdfMode={pdfMode}>
                  <View className="row justify-content-center">
                    <View className="row d-block ">
                      {invoice.locations?.length === 0 ? ("") : <>
                        <View className="w-50 mt-10 bold px-5 locationLable" pdfMode={pdfMode}>
                          <Text value='Locations' className={pdfMode ? "px-5" : "text-center"} pdfMode={pdfMode}>Locations</Text>
                        </View>

                        <View className="locationContainer">

                          {invoice.locations.map((loc, i) => (
                            <View key={i} className="flex rowFocu " pdfMode={pdfMode}>
                              <View className="flex invoiceTxt " pdfMode={pdfMode}>
                                {i + 1}
                                <EditableInput className={pdfMode ? "dark center" : "dark w-85  center"} placeholder="City" value={pdfMode ? invoice.locations[i].city + "," : invoice.locations[i].city} readOnly={editable ? false : true} onChange={(value) => handleLocationChange(i, 'city', value)} pdfMode={pdfMode} />
                                <EditableInput className={pdfMode ? "dark center" : "dark w-15  center"} maxLength={2} placeholder="State" value={invoice.locations[i].state.toUpperCase()} readOnly={editable ? false : true} onChange={(value) => handleLocationChange(i, 'state', value)} pdfMode={pdfMode} />
                              </View>
                              {!pdfMode && (editable ? <button className="link row__remove" aria-label="Remove Row" title="Remove Row" onClick={(value) => handleRemoveLoc(i, value)}>
                                <span className="iconLoc icon-remove bg-red"></span>
                              </button> : '')}
                            </View>
                          ))
                          }
                        </View>
                      </>
                      }


                      {invoice.locations.length <= 7 ? (!pdfMode && (editable ? <View className='row w-50'> <Button variant="white" className='bg-white link' onClick={() => handleAddLoc()}>
                        <span className="iconAdd icon-add bg-green mr-10"></span>
                        Add Location
                      </Button></View> : '')) : ('')}

                    </View>
                    <View className={pdfMode ? 'row' : " flex row"} pdfMode={pdfMode}>
                      <View className="w-70 mt-10 bold row" pdfMode={pdfMode}>
                        <Text className={pdfMode ? " px-5   " : "text-center  locationLable notes "} pdfMode={pdfMode}>Notes</Text>
                        <EditableTextarea className="w-100 invoiceTxt notesBox" rows={3} readOnly={editable ? false : true} value={invoice.ConsultantNotes} onChange={(value) => handleChange('ConsultantNotes', value)} pdfMode={pdfMode} />
                      </View>
                      <View className={pdfMode ? 'w-30 mt-10 bold floatleft' : "w-30 mt-10 bold "} pdfMode={pdfMode}>
                        <Text className={pdfMode ? "mb-0 fs-12" : "text-center mb-0 fs-12"} value='Reportable' pdfMode={pdfMode}>Reportable</Text>
                        {editable ? <EditableSelect className={pdfMode ? "w-100 border border-light-subtle border-3 " : "w-100  text-center border border-light-subtle border-3 "} value={invoice.Reportable} options={reportable} onChange={(value) => handleChange('Reportable', value)} pdfMode={pdfMode} /> :

                          <EditableInput placeholder="" className={pdfMode ? " invoiceTxt  border border-light-subtle border-3" : "dark left noHover invoiceTxt text-center  border border-light-subtle border-3"} readOnly={true} value={invoice.Reportable === "Reportable" ? 'Yes' : 'No'} pdfMode={pdfMode} />
                        }

                      </View>
                    </View>
                  </View>
                </View>
              </View>



            </View>

          </Page>
          {pdfMode ? ('') : (<Container>
            <Row className="justify-content-center mt-3 d-flex">
              {checkForm() && editable ? <p className="text-danger text-center">There must be values in Consultant Invoice, Line Items, and Reportable to Save Draft or Submit Invoice</p> : ''}

              {alertShow ? <> <Row className="justify-content-center   d-flex"> <AlertMsg message={alertMsg} vari={alertVari} title={alertTitle} /></Row> </> : ('')}
              {btnSelect ? ("") : (<Col sm className="justify-content-center d-flex my-2">

                {!pdfMode && <Button style={{ width: "150px" }} variant="secondary"><DownloadPDF fileName={invoice.Company_Name + "-" + invoice.Invoice_Number} data={invoice} /></Button>}
              </Col>)}


              {!btnSelect ? ("") : (<> <Col sm className="justify-content-center d-flex my-2">

                {!pdfMode && <Confirm headingTxt="Save Draft Confrimation" isLoading={isLoading} disabled={checkForm()} onConfirm={(e) => handleDraft(e)} bodyMsg={`Confirm you want to save a draft invoice for  ${invoice.Company_Name}.`} action="Save Draft" vari="success" />}

              </Col>
                <Col sm className="justify-content-center d-flex my-2">
                  {!pdfMode && <Confirm headingTxt="Submit Invoice Confrimation" disabled={checkForm()} onConfirm={(e) => handleSubmit(e)} bodyMsg={`Confirm you want to submit an invoice for ${invoice.Company_Name}. You will not be able to edit this invoice later. `} action="Submit Invoice" vari="primary" />}
                </Col> </>)}

            </Row>
          </Container>)}
        </Document>
      </>
    )
    }



  </>
  )
}


export default Invoice

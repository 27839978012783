import { React, useState } from "react";
 
 
import {  Button, Alert, Modal, Spinner } from "react-bootstrap";



function Confirm({headingTxt,bodyMsg,vari, onConfirm,action,disabled, isLoading} ) {
    const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
 
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                variant="light"
                className="d-flex"
            >
                <Alert
                    show={show}
                    variant="light"
                    onClose={() => setShow(false)}
                    dismissible
                    style={{ marginBottom: "0px" }}
                >
                    <Alert.Heading>{headingTxt}</Alert.Heading>
                    <p>
                      {bodyMsg}
                    </p>

                    <div className="d-flex justify-content-end">
                        <Button
                            onClick={(e) => {onConfirm(e); setShow(false)}}
                            variant={vari}
                            
                        >
                            {action}
                        </Button>
                    </div>
                </Alert>
            </Modal>
            {!show && (
                <Button disabled={disabled}  style={{ width: "150px" }} onClick={() => setShow(true)} variant={vari}>
                   {isLoading ? <Spinner/> : action}
                </Button>
            )}
        </>
    );
}
export default Confirm